import styled from "styled-components";

export const StyledCandidateFeaturesWrapper = styled.div`
  width: 100%;
  /* min-height: 100vh; */
  height: auto;
  background: linear-gradient(
    107.79deg,
    rgba(255, 255, 255, 0.58) 1.15%,
    rgba(225, 232, 250, 0.58) 78.05%,
    rgba(217, 188, 255, 0.58) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 0;

  @media screen and (max-width: 768px) {
    height: 100vh;
    padding: 4rem 1rem;
  }
`;
export const StyledCandidateFeaturesHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;

  button {
    width: 45px;
    height: 45px;
    background-color: #fff;
    border-radius: 50%;
    border: 0.5px solid #979797;
    cursor: pointer;
  }

  h3 {
    font-size: 40px;
    font-weight: 700;
    line-height: 58.8px;
    letter-spacing: -0.015em;
    text-align: left;
    color: #000;
  }

  @media screen and (max-width: 768px) {
    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
    }
  }
`;

export const StyledCandidateFeaturesItemsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCandidateFeatureItem = styled.div`
  width: 100%;
  max-width: 981px;
  min-height: 544px;
  background-color: #fff;
  border-radius: 24px;
  color: #000;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;

  gap: 1rem;

  @media screen and (max-width: 768px) {
    & > span {
      background: linear-gradient(113.69deg, #e3ffd7 1.79%, #e3d0fb 78.8%);
      width: 199px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
    }
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledCandidatesFeaturesItemContentWrapper = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  span {
    background: linear-gradient(113.69deg, #e3ffd7 1.79%, #e3d0fb 78.8%);
    width: 199px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
  }

  h6 {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }

  @media screen and (max-width: 768px) {
    align-items: center;
    text-align: center;
    gap: 0.5rem;
    h6 {
      font-size: 18px;
      font-weight: 700;
      line-height: 36px;
      text-align: center;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
      text-align: center;
    }
  }
`;
export const StyledCandidatesFeaturesItemImgtWrapper = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCandidatesFeaturesItemImgtWrapperSmall = styled.div`
  position: relative;
  margin-top: 1rem;
  height: 35vh;
`;

export const StyledCandidatesFeaturesItemContentWrapperSmall = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;

  h6 {
    font-size: 18px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    max-width: 90%;
  }
`;

export const StyledCandidatesFeaturesItemVideo1 = styled.div`
  width: 180px;
  height: 180px;
  position: absolute;
  top: 0;
  right: 0;

  & > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 3px solid;
    border-image: linear-gradient(
      113.69deg,
      #6691ff 1.79%,
      #beefa9 61.55%,
      #c566ff 93.9%
    );
    border-image-slice: 1;
  }
`;
export const StyledCandidatesFeaturesItemVideo1Small = styled.div`
  width: 180px;
  height: 180px;

  position: absolute;
  bottom: 0;
  right: 0;

  & > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 3px solid;
    border-image: linear-gradient(
      113.69deg,
      #6691ff 1.79%,
      #beefa9 61.55%,
      #c566ff 93.9%
    );
    border-image-slice: 1;
  }
`;
export const StyledCandidatesFeaturesItemVideo2Small = styled.div`
  width: 180px;
  height: 180px;

  position: absolute;
  top: 0;
  left: 0;

  & > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 3px solid;
    border-image: linear-gradient(
      113.69deg,
      #6691ff 1.79%,
      #beefa9 61.55%,
      #c566ff 93.9%
    );
    border-image-slice: 1;
  }
`;

export const StyledCandidatesFeaturesItemVideo2 = styled.div`
  width: 180px;
  height: 180px;

  position: absolute;
  bottom: 20px;
  left: 1;

  & > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 3px solid;
    border-image: linear-gradient(
      113.69deg,
      #6691ff 1.79%,
      #beefa9 61.55%,
      #c566ff 93.9%
    );
    border-image-slice: 1;
  }
`;

export const StyledCandidatesFeaturesImgWrapperSlider2 = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;

  img {
    width: 100%;
    height: 100%;

    max-width: 372px;
    max-height: 373px;

    object-fit: cover;
  }

  @media (max-width: 768px) {
    img {
      width: 90%;
      height: 90%;
    }
  }
`;

export const StyledCandidateFeatureItemSmall = styled.div`
  width: 100%;
  height: auto;
  padding: 2rem 1rem;

  background-color: #fff;
  border-radius: 34px;
  z-index: 9;

  color: #000;

  span {
    background: linear-gradient(113.69deg, #e3ffd7 1.79%, #e3d0fb 78.8%);
    width: 199px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
  }
`;
