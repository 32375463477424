import styled from "styled-components";

export const StyledInsightsWrapper = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  padding: 0 4rem;
  height: 100vh;


  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 2rem 1rem;

    h3 {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      max-width: 70%;
    }
  }
`;

export const StyledInsightsContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  justify-content: center;
  width: 100%;
  height: 100%;

  h3 {
    max-width: 95%;
    font-size: 38px;
    font-weight: 700;
    line-height: 50px;
    text-align: left;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    max-width: 90%;
  }

  @media screen and (max-width: 768px) {
    gap: 3rem;
    align-items: center;
    p {
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      max-width: 90%;
      margin-top: 1rem;
    }
  }
`;

export const StyledInsightsImgContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    
   max-width: 769px;
    max-height: 600px;
  }
`;
