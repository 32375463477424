import React from "react";
import "./index.css";
import Header from "../Header";
import Welcome from "./Welcome/index.js";
import FeatureList from "./FeatureList";
// import Filler from "./Filler";
import NudgeToDownload from "./NudgeToDownload";
import Profile from "./Profile";
import Footer from "../Footer";
import Employers from "./Employers";
// import Values from "./Values";
import Testimonials from "./Testimonials";
import Colleges from "./CollegeStripe/Colleges.jsx";
import FeaturesOverView from "./FeaturesOverView/FeaturesOverView.jsx";
import CareerTasters from "./CareerTasters/CareerTasters.jsx";
import CommonHelmet from "../Common/CommonHelmet/CommonHelmet.js";

const Home = () => {
  return (
    <>
      <CommonHelmet
        title="MapOut: Your Ultimate Platform for Career Planning | Connect with Top Coaches & Employers"
        description="MapOut is the ultimate platform to plan your career. Identify the best-fit careers, connect with top career coaches, employers, and showcase your skills to employers worldwide."
      />
      
      <div className="home-main-container">
        <Header currentPath="/" />
        <div>
          <Welcome />
        </div>
        <div>
          <Colleges />
        </div>
        <div>
          <CareerTasters />
        </div>
        <div>
          <FeaturesOverView />
        </div>
        <div>
          <Profile />
        </div>
        <div style={{ height: "400vh" }}>
          <FeatureList />
        </div>
        {/* <div>
        <Filler />
      </div> */}
        <div>
          <Employers />
        </div>
        {/* <div>
          <Values />
        </div> */}
        <div>
          <Testimonials />
        </div>
        <div>
          <NudgeToDownload />
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Home;
