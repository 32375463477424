import React, { useEffect, useState } from "react";
import "./index.css";

const SmallAboutUsMission = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Device1 = windowDimensions.width < 380 && windowDimensions.height < 670;

  return (
    <div className="about-mapout-mission-container">
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          flex: 1,
          justifyContent: "center",
          height: "90%",
          gap: "1rem",
        }}
      >
        <div
          style={{
            background: 'linear-gradient(167.45deg, rgba(185, 228, 166, 0.75) 2.97%, rgba(194, 236, 205, 0.75) 54.64%, rgba(202, 243, 242, 0.55) 82.05%)',
            width: "75vw",
            height: "75vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
            marginTop: `${Device1 ? "8vh" : "3vh"} `,
            flexDirection: "column",
            gap: "1rem",
            borderRadius: "50%",
          }}
        >
          <p style={{ color: "#000", fontSize: "20px", fontWeight: "700" }}>
            OUR MISSION
          </p>
          <p
            style={{
              color: "#000",
              fontSize: "14px",
              fontWeight: "400",
              textAlign: "center",
              maxWidth: "90%",
            }}
          >
            Our mission is to equip every individual with the confidence and{" "}
            <b> roadmap for a fulfilling career, not just a job </b>.
          </p>
        </div>
        <div
          style={{
            background: 'linear-gradient(347.45deg, rgba(185, 228, 166, 0.75) 3.09%, rgba(194, 236, 205, 0.75) 54.77%, rgba(202, 243, 242, 0.55) 82.17%)',
            width: "75vw",
            height: "75vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
            marginTop: `${Device1 ? "8vh" : "3vh"} `,
            flexDirection: "column",
            gap: "1rem",
            borderRadius : "50%",
          }}
        >
          <p style={{ color: "#000", fontSize: "20px", fontWeight: "700" }}>
          OUR VISION
          </p>
          <p
            style={{
              color: "#000",
              fontSize: "14px",
              fontWeight: "400",
              textAlign: "center",
              maxWidth: "90%",
            }}
          >
             Our vision is to{" "}
            <b> empower everyone with the tools and support </b> to achieve a{" "}
            <b> fulfilling and purposeful career, </b> unlocking their full
            potential and navigating their career paths with confidence and
            clarity.
          </p>
        </div>
        {/* <div
          style={{
            backgroundColor: "rgba(255,255,255,0.45)",
            width: `${Device1 ? "105vw" : "110vw"} `,
            height: `${Device1 ? "100vw" : "110vw"} `,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            display: "flex",
            backgroundPosition: "center",
            marginTop: "-8vw",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
            borderRadius: "50%",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <p style={{ color: "#000", fontSize: "20px", fontWeight: "700" }}>
            OUR VISION
          </p>
          <p
            style={{
              color: "#000",
              fontSize: "15px",
              fontWeight: "500",
              textAlign: "center",
              maxWidth: "80%",
              lineHeight: "21.3px",
            }}
          >
            Our vision is to{" "}
            <b> empower everyone with the tools and support </b> to achieve a{" "}
            <b> fulfilling and purposeful career, </b> unlocking their full
            potential and navigating their career paths with confidence and
            clarity.
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default SmallAboutUsMission;
