import React from "react";
import "./index.css";
import MapoutLogoLarge from "../../../Assets/Icons/Mapout-logo-large.svg";
import PlayStoreLogo from "../../../Assets/Icons/play-store-logo.svg";
import AppStoreLogo from "../../../Assets/Icons/apple-logo.svg";
import ArrowSvg from "../../../Assets/Icons/arrow.svg";
import background from "../../../Assets/Images/HomeBackground.png";
// import { submitEmail } from "../../../Api/waitinglist";

const BigWelcome = () => {
  // const [email, setEmail] = useState('');
  // const [signupClass, setSignupClass] = useState("home-bottom-signup-container");
  // const [isSubmitted, setIsSubmitted] = useState(false);

  const onClickUrl = (url) => {
    return () => {
      window.open(url, "_blank");
    };
  };

  // const handleSignupClicked = () => {
  //   // eslint-disable-next-line
  //   if(email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
  //     let response = submitEmail({ email });
  //     if(response) {
  //       setIsSubmitted(true);
  //     }
  //   } else {
  //     setSignupClass('home-bottom-signup-container-error');
  //   }
  // }

  // const handleEmailChanged = (e) => {
  //   if(signupClass === 'home-bottom-signup-container-error') {
  //     // eslint-disable-next-line
  //     if(email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
  //       setSignupClass('home-bottom-signup-container');
  //     }
  //   }
  //   setEmail(e.target.value);
  // }

  return (
    <div className="home-container">
      <div className="welcome-animation"></div>
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          zIndex: 1,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          // marginTop : "3rem",
        }}
        className="home-sub-container"
      >
        <img src={MapoutLogoLarge} alt="" className="mapout-large-logo" />
        <p className="home-sub-container-text">Your complete Career Ecosystem</p>

        <div className="features-listing-container">
          <h4>CAREER COACHING</h4>
          <span />
          <h4>CAREER EXPLORATION</h4>
          <span />
          <h4>TALENT TRIBE</h4>
        </div>

        <div className="home-sub-conatiner-download-buttons">
          <button
            onClick={onClickUrl(
              "https://apps.apple.com/app/mapout-global/id6451142534"
            )}
            className="home-apple-button"
          >
            <img
              src={AppStoreLogo}
              alt=""
              className="apple-logo"
              style={{ marginBottom: "5px" }}
            />
            <p className="home-download-text">Download on App store</p>
            <img src={ArrowSvg} alt="" className="arrow-svg-download" />
          </button>
          <button
            onClick={onClickUrl(
              "https://play.google.com/store/apps/details?id=com.mapout"
            )}
            className="home-play-store-button"
          >
            <img src={PlayStoreLogo} alt="" className="play-store-logo" />
            <p className="home-download-text">Get it on Play Store</p>
            <img src={ArrowSvg} alt="" className="arrow-svg-download" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default BigWelcome;
