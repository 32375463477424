import React from "react";
import "./index.css";
import Header from "../Header";
import Footer from "../Footer";
import Discover from "./Discover";
import Colleges from "../Home/CollegeStripe/Colleges";
import TalentPowerHouse from "./TalentPowerHouse/TalentPowerHouse";
import Candidates from "./Candidates/Candidates";
import StartFree from "./StartFree/StartFree";
import DiversityAndInclusion from "./DiversityAndInclusion/DiversityAndInclusion";
import Insights from "./Insights/Insights";
import ValueAdd from "./ValueAdd/ValueAdd";
import JoinMapout from "./JoinMapout/JoinMapout";
import { ScrollProvider, useScroll } from "../../Utils/scrollContext";
import CandidateFeatures from "./CandidateFeatures/CandidateFeatures";
import CommonHelmet from "../Common/CommonHelmet/CommonHelmet";

const ForEmployer = () => {
  const { joinMapoutRef } = useScroll();

  return (
    <>
      <CommonHelmet
        title="MapOut Employers: Data-driven, Intelligent, Simple Talent Acquisition Tools for Every Employer."
        description="Discover the best talent with MapOut's AI-driven talent acquisition tools all in one place."
      />
      <div className="employer-main-container">
        <Header currentPath="/employers" />
        <div>
          <Discover />
        </div>
        <div>
          <Colleges />
        </div>
        <div>
          <TalentPowerHouse />
        </div>
        <div>
          <CandidateFeatures />
        </div>
        <div>
          <Candidates />
        </div>
        <div>
          <StartFree />
        </div>
        <div>
          <DiversityAndInclusion />
        </div>
        <div>
          <Insights />
        </div>
        <div>
          <ValueAdd />
        </div>
        <div ref={joinMapoutRef}>
          <JoinMapout />
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </>
  );
};

const App = () => (
  <ScrollProvider>
    <ForEmployer />
  </ScrollProvider>
);

export default App;
