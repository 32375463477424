import React, { useEffect, useState } from "react";
import {
  StyledDiversityAndInclusionContainer,
  StyledDiversityContentWrapper,
  StyledDiversityImgWrapper,
} from "./DiversityAndInclusion.styled";
import DiversityAndInclusionImage from "../../../Assets/Images/diversityImage.png";
import CommonBlackBgButton from "../../Common/Utils/Buttons/CommonBlackBgButton";
import { useScroll } from "../../../Utils/scrollContext";

const DiversityAndInclusion = () => {
  const  { scrollToJoinMapout } = useScroll()
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    const mediaQuery = window.matchMedia("(min-width: 768px)");
    mediaQuery.addEventListener("change", handler);
    return () => {
      mediaQuery.removeEventListener("change", handler);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return matches ? (
    <>
      {" "}
      <StyledDiversityAndInclusionContainer>
        <StyledDiversityImgWrapper>
          <img src={DiversityAndInclusionImage} alt="diversity and inclusion" />
        </StyledDiversityImgWrapper>

        <StyledDiversityContentWrapper>
          <h3>Invest in diversity & inclusion.</h3>

          <p>
            MapOut dismantles traditional recruitment barriers by connecting you
            with a vibrant pool of pre-skilled talent who brings fresh
            perspectives, essential skills, and unstoppable enthusiasm to join
            your teams.
          </p>

          <CommonBlackBgButton onClick={scrollToJoinMapout} >Get Started</CommonBlackBgButton>
        </StyledDiversityContentWrapper>
      </StyledDiversityAndInclusionContainer>
    </>
  ) : (
    <>
      <StyledDiversityAndInclusionContainer>
        <h3>Invest in diversity & inclusion.</h3>
        <StyledDiversityImgWrapper>
          <img src={DiversityAndInclusionImage} alt="diversity and inclusion" />
        </StyledDiversityImgWrapper>

        <StyledDiversityContentWrapper>
          <p>
            MapOut dismantles traditional recruitment barriers by connecting you
            with a vibrant pool of pre-skilled talent who brings fresh
            perspectives, essential skills, and unstoppable enthusiasm to join
            your teams.
          </p>

          <CommonBlackBgButton onClick={scrollToJoinMapout} >Get Started</CommonBlackBgButton>
        </StyledDiversityContentWrapper>
      </StyledDiversityAndInclusionContainer>
    </>
  );
};

export default DiversityAndInclusion;
