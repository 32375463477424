import React, { useEffect, useState } from "react";
import {
    StyledArrowWrapper,
  StyledSmallValueAddWrapper,
  StyledValueAddItem,
  StyledValueAddItemsContainer,
  StyledValueAddWrapper,
} from "./ValueAdd.styled";

import icon1 from "../../../Assets/Icons/valueAddIcons/1.png";
import icon2 from "../../../Assets/Icons/valueAddIcons/2.png";
import icon3 from "../../../Assets/Icons/valueAddIcons/3.png";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Pagination, Navigation } from "swiper/modules";
import ArrowButton from "../../Common/Utils/Buttons/ArrowButton";

const ValueAdd = () => {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    const mediaQuery = window.matchMedia("(min-width: 768px)");
    mediaQuery.addEventListener("change", handler);
    return () => {
      mediaQuery.removeEventListener("change", handler);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const itemsData = [
    {
      id: 1,
      icon: icon1,
      title: "Supercharge your Talent Pipeline",
      desc: `Eliminate the wait for applications. MapOut is like having a dedicated recruitment team, powered by cutting-edge AI, constantly working to identify and build a pipeline of top talent, saving you valuable time and resources. `,
    },
    {
      id: 2,
      icon: icon2,
      title: "Elevate Your Employer Brand  ",
      desc: `Attract the best talent by showcasing your company culture as a valuable asset. MapOut empowers you to craft captivating profile that tell a compelling story about your team, values, and what it's like to be a part of your organization.`,
    },
    {
      id: 3,
      icon: icon3,
      title: "Move beyond limited Resumes",
      desc: `Explore rich candidate profiles with showcase of skills, experience, and potential. Take it a step further with "Career Tasters"  to observe their work ethic and assess potential in a dynamic setting for smarter hiring decisions.`,
    },
  ];
  return matches ? (
    <>
      <StyledValueAddWrapper>
        <h3>Begin your MapOut journey & fuel your talent strategy </h3>

        <StyledValueAddItemsContainer>
          {itemsData.map((item) => (
            <StyledValueAddItem key={item.id}>
              <img src={item.icon} alt={item.title} />
              <h4>{item.title}</h4>
              <p>{item.desc}</p>
            </StyledValueAddItem>
          ))}
        </StyledValueAddItemsContainer>
      </StyledValueAddWrapper>
    </>
  ) : (
    <>
      <StyledSmallValueAddWrapper>
        <h3>Begin your MapOut journey & fuel your talent strategy </h3>
        <StyledValueAddItemsContainer>
          <Swiper
            slidesPerView={1}
            spaceBetween={30}

            navigation={{
              nextEl: ".next-valueAdd",
              prevEl: ".prev-valueAdd",
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper-valueAdd"
          >
            {itemsData.map((item) => (
              <SwiperSlide>
                <StyledValueAddItem key={item.id}>
                  <img src={item.icon} alt={item.title} />
                  <h4>{item.title}</h4>
                  <p>{item.desc}</p>
                </StyledValueAddItem>
              </SwiperSlide>
            ))}
          </Swiper>
        </StyledValueAddItemsContainer>

        <StyledArrowWrapper >
          <ArrowButton className="prev-valueAdd" direction="left" />
          <ArrowButton className="next-valueAdd" direction="right" />
        </StyledArrowWrapper>
      </StyledSmallValueAddWrapper>
    </>
  );
};

export default ValueAdd;
