import React from "react";
import {
  StyledCollegeHeadingText,
  StyledCollegeLogo,
  StyledCollegeWrapper,
  StyledLogoSlideContainer,
  StyledLogoSlideWrapper,
} from "./Colleges.styled";
import { trustedLogos } from "../../../Utils/collegeLogos";

const Colleges = () => {
  return (
    <StyledCollegeWrapper>
      <StyledCollegeHeadingText>
      Trusted by students & professionals from
      </StyledCollegeHeadingText>
      <StyledLogoSlideContainer>
        {[1,2,3].map(() => (
          <StyledLogoSlideWrapper>
            {trustedLogos.map((item, index) => (
              <StyledCollegeLogo src={item} alt=""  />
            ))}
          </StyledLogoSlideWrapper>
        ))}
      </StyledLogoSlideContainer>
    </StyledCollegeWrapper>
  );
};

export default Colleges;
