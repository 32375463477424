import React, { useEffect, useState } from "react";
import "./index.css";

import ArrowLeft from "../../../Assets/Images/arrow_left.png";
import ArrowRight from "../../../Assets/Images/arrow_right.png";

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
};

const transformSection = (section, scrollY) => {
  const offsetTop = section?.parentElement?.offsetTop;
  const scrollSection = section.querySelector(".horizontal_scroll");
  let percentage = ((scrollY - offsetTop) / window.innerHeight) * 100;
  percentage = Math.max(0, Math.min(percentage, 300));
  scrollSection.style.transform = `translate3d(${-percentage}vw, 0, 0)`;
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => setWindowDimensions(getWindowDimensions());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

const useScrollTransform = () => {
  useEffect(() => {
    const handleScroll = () => {
      document
        .querySelectorAll(".sticky_wrap")
        .forEach((section) => transformSection(section, window.scrollY));
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
};

const ScrollContent = ({ data }) => (
  <div
    className="scroll_contents"
    style={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: data.backgroundColor,
      position: "relative",
    }}
  >
    {Array.from({ length: 4 }, (_, i) => (
      <div
        key={i}
        className="feature-animation"
        style={{
          width: `${80 + i * 20}vh`,
          height: `${80 + i * 20}vh`,
          borderRadius: `${80 + i * 20}vh`,
          backgroundColor: `rgba(255,255,255,${0.5 - i * 0.15})`,
          position: "absolute",
          zIndex: 1,
          margin: i === 0 ? "100px" : 0,
        }}
      ></div>
    ))}
    <h6
      style={{
        position: "absolute",
        zIndex: 10,
        top: "50%",
        left: "5%",
        fontSize: "3.6rem",
        fontWeight: "800",
        writingMode: "vertical-rl",
        transform: "rotate(180deg) translateX(-50%)  translateY(50%)",
        whiteSpace: "nowrap",
      }}
    >
      {data.text}
    </h6>
    <img
      style={{ height: "85vh", width: "auto", zIndex: 10  , marginTop :  data.text === "Career Discovery" && "5rem" }}
      src={data.imgSrc}
      alt="Feature"
    />
  </div>
);

const NavButton = ({ direction, onClick }) => (
  <button
    style={{
      border: "none",
      borderRadius: "200px",
      height: "40px",
      width: "40px",
      backgroundColor: "rgba(255,255,255,0.56)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "1.5rem",
      cursor: "pointer",
    }}
    onClick={onClick}
  >
    <img
      src={direction === "left" ? ArrowLeft : ArrowRight}
      alt=""
      height="10px"
    />
  </button>
);

const BigFeatureList = () => {
  const windowDimensions = useWindowDimensions();
  useScrollTransform();

  const contents = [
    {
      text: "Jobs",
      backgroundColor: "#D8E3FC",
      imgSrc: require(`../../../Assets/Images/FeatureListImages/BigScreen/bigImage2.png`),
    },
    {
      text: "Coaching",
      backgroundColor: "#B9E4A6BF",
      imgSrc: require(`../../../Assets/Images/FeatureListImages/BigScreen/bigImage1.png`),
    },
    {
      text: "Career Discovery",
      backgroundColor: "rgba(217, 188, 255, 0.75)",
      imgSrc: require(`../../../Assets/Images/FeatureListImages/BigScreen/bigImage3.png`),
    },
    {
      text: "Feed",
      backgroundColor: "rgba(102, 145, 255, 0.6)",
      imgSrc: require(`../../../Assets/Images/FeatureListImages/BigScreen/bigImage4.png`),
    },
  ];

  const handleClick = (dir) => {
    const stickySection = document.querySelector(".sticky_wrap");
    const offsetTop = stickySection.parentElement.offsetTop;
    const scrollY = window.scrollY;
    const sectionHeight = windowDimensions.height;

    let newScrollPosition = scrollY;

    if (dir === "left") {
      if (scrollY > offsetTop && scrollY <= offsetTop + sectionHeight) {
        newScrollPosition = offsetTop;
      } else if (
        scrollY > offsetTop + sectionHeight &&
        scrollY <= offsetTop + sectionHeight * 2
      ) {
        newScrollPosition = offsetTop + sectionHeight;
      } else if (
        scrollY > offsetTop + sectionHeight * 2 &&
        scrollY <= offsetTop + sectionHeight * 3
      ) {
        newScrollPosition = offsetTop + sectionHeight * 2;
      }
    } else {
      if (scrollY >= offsetTop && scrollY < offsetTop + sectionHeight) {
        newScrollPosition = offsetTop + sectionHeight;
      } else if (
        scrollY >= offsetTop + sectionHeight &&
        scrollY < offsetTop + sectionHeight * 2
      ) {
        newScrollPosition = offsetTop + sectionHeight * 2;
      } else if (
        scrollY >= offsetTop + sectionHeight * 2 &&
        scrollY < offsetTop + sectionHeight * 3
      ) {
        newScrollPosition = offsetTop + sectionHeight * 3;
      }
    }

    window.scrollTo({ top: newScrollPosition, behavior: "smooth" });
  };

  return (
    <div
      className="sticky_wrap"
      style={{
        height: "100vh",
        position: "sticky",
        top: 0,
        overflow: "hidden",
      }}
    >
      <div
        className="horizontal_scroll"
        style={{
          width: `${contents.length * 100}vw`,
          position: "absolute",
          height: "100vh",
          top: 0,
          willChange: "transform",
          display: "flex",
        }}
      >
        {contents.map((content, index) => (
          <ScrollContent key={index} data={content} />
        ))}
      </div>
      <div
        style={{
          position: "absolute",
          zIndex: 1,
          bottom: "32px",
          right: "48px",
          display: "flex",
          gap: "8px",
        }}
      >
        <NavButton direction="left" onClick={() => handleClick("left")} />
        <NavButton direction="right" onClick={() => handleClick("right")} />
      </div>
    </div>
  );
};

export default BigFeatureList;
