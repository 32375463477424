import React from "react";
import {
  GalleryImage,
  GallerySmallContainer,
  JoinUsSectionSmallContainer,
  JoinUsSmallContainer,
} from "./bigScreen.styled";
import galleryImageItem from "../../../Assets/Images/galleryImageNewSmall.png";

const SmallJoinUs = () => {

  return (
    <JoinUsSectionSmallContainer>
      <JoinUsSmallContainer>
        <h6>Join our journey of making career planning simple, accessible and fun.</h6>

        <p>
          Looking to join us? Send your cv to{" "}
          <a href="mailto:hina@mapout.com"> hina@mapout.com </a> even if you
          don't see a specific role!
        </p>
      </JoinUsSmallContainer>
      <GallerySmallContainer>
        <GalleryImage src={galleryImageItem} />
      </GallerySmallContainer>
    </JoinUsSectionSmallContainer>
  );
};

export default SmallJoinUs;
