import React from "react";
import "./index.css";

// import { redirectToAppStore } from "../../../Utils/platformCheck";

const BigNudgeToDownload = () => {
  const handleSignUpClicked = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div
      style={{
        backgroundImage: `rgba(255, 255, 255, 1)`,
        backgroundSize: "cover",
        backgroundPosition: "top center",
        width: "100%",
        height: "100vh",
        display: "flex",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          flex: 1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <img
          style={{ height: "80vh", width: "auto" }}
          src={require(`../../../Assets/Images/nudgeImageWithbgBig.png`)}
          alt="Company Logo"
        />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "baseline",
        }}
      >
        <div style={{ padding: "0 120px" }}>
          <p style={{ color: "#000", fontSize: "38px", fontWeight: "700" , maxWidth : '80%' }}>
            Never feel short of career support again.
          </p>
          <p
            style={{
              color: "#000",
              fontSize: "20px",
              fontWeight: "400",
              margin: "32px 0",
              maxWidth : '80%'
            }}
          >
            Access MapOut for free and join your peers gaining career advantage
            with us!{" "}
          </p>
          <button
            onClick={handleSignUpClicked}
            style={{
              border: "none",
              backgroundColor: "#000",
              color: "#FFF",
              padding: "12px 32px",
              borderRadius: "20px",
              cursor: "pointer",
            }}
          >
            Sign Up for Free
          </button>
        </div>
      </div>
    </div>
  );
};

export default BigNudgeToDownload;
