import React from "react";
import "./index.css";
import Header from "../Header";
import Footer from "../Footer";
import JobOpenings from "./NewJobOpenings/JobOpenings";
import JoinUs from "../AboutUs/JoinUs";
import CommonHelmet from "../Common/CommonHelmet/CommonHelmet";

const OpenPositions = () => {
  return (
    <>
      <CommonHelmet
        title="Join MapOut: Looking for like-minded, tech-driven, curious individuals dedicated to the mission of making all jobs accessbile to everyone reverywhere."
        description="Join our MapOut Team. Open positions in Tech, BD, HR, Marketing, and Strategy with great benefits, fun work environment and endless opportunities to grow."
      />
      <div className="open-poition-main-container">
        <Header currentPath="/open-positions" />
        <div>
          <JobOpenings />
        </div>
        <div>
          <JoinUs />
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default OpenPositions;
