import React from "react";
import "./index.css";
import Lottie from "lottie-react";
// import { redirectToAppStore } from "../../../Utils/platformCheck";
import Animation from "../../../Assets/lottie/interview.json";

const SmallEmployers = () => {
  const handleSignUpClicked = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="filler-container">
      <div className="top-container">
        <p className="top-text">Discover & Engage with</p>
        <p className="top-text">Employers like never before</p>
        <Lottie animationData={Animation} loop className="filler-image" />
      </div>

      <div className="bottom-container">
        <div className="filler-text">
          <p>
            Imagine scrolling through profiles not just of job listings, but of
            employers themselves. With video profiles showcasing their company
            culture, values, and the faces behind the brand, you'll get an
            inside look like never before.
          </p>
          <br />
          <p>
            Explore what employers are looking for in candidates, hear directly
            from employees about their experiences, and get a feel for whether
            it's the right fit for you.
          </p>
        </div>
        <button onClick={handleSignUpClicked} className="join-button">
          Download Now
        </button>
        {/* <button onClick={redirectToAppStore} className="join-button">Join Now</button> */}
      </div>
    </div>
  );
};

export default SmallEmployers;
