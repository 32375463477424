import styled from "styled-components";

export const StyledJobOpeningsContainer = styled.div`
  width: 100%;
  height: auto;
  background: linear-gradient(
    113.69deg,
    #ffffff 3.27%,
    #e2d6f4 62.22%,
    #d8e3fc 93.9%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000000;
  padding: 10rem 3rem;
  gap: 1rem;

  & > h3 {
    font-size: 50px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
  }

  & > p {
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    max-width: 70%;
    margin: 1rem;
  }

  & > span {
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    max-width: 1165px;
    padding: 5px;
  }

  @media screen and (max-width: 768px) {
    padding: 5rem 1rem;

    & > h3 {
      font-size: 24px;
      font-weight: 600;
      line-height: 30px;
      text-align: left;
    }

    & > p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
    }
  }
`;

export const StyledJobItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  width: 100%;
  padding: 2rem 0;
`;

export const StyledJobItemsContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5rem;
  background-color: re;

  & > h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: 44px;
    text-align: left;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 0.5rem;

    & > p {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      text-align: left;
    }
  }

  @media screen and (max-width: 768px) {
    & > h4 {
      font-size: 16px;
      font-weight: 600;
      line-height: 25px;
      text-align: left;
    }

    & > div {
      gap: 0.2rem;
      flex-wrap: wrap;
      align-items: center;
      & > p {
        font-size: 12px;
        font-weight: 400;
        line-height: 16.67px;
        text-align: left;
      }
    }
  }
`;
export const StyledjobItemWrapper = styled.div`
  width: 100%;
  max-width: 1164px;
  min-height: 135px;
  background-color: #fff;
  border-radius: 12px;
  padding: 1rem 2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  cursor: pointer;

  &:hover {
    background-color: #f6f3ff;
    border: 1px solid #dbcfff;
  }

  img {
      width: 50px;
      height: 50px;
      transform: scaleX(-1);
    }

  @media screen and (max-width: 768px) {
    img {
      width: 26px;
      height: 26px;
    }
  }
`;
