import React from "react";
import {
  StyledStartFreeContainer,
  StyledStartFreeContentWrapper,
  StyledStartFreeImgWrapper,
} from "./StartFree.styled";
import CommonBlackBgButton from "../../Common/Utils/Buttons/CommonBlackBgButton";
import { useScroll } from "../../../Utils/scrollContext";
import prmptAnimation from "../../../Assets/lottie/prompt.json"
import Lottie from "lottie-react";

const StartFree = () => {
  const  { scrollToJoinMapout } = useScroll()

  return (
    <StyledStartFreeContainer>
      <StyledStartFreeContentWrapper>
        <h3>BUILD YOUR TALENT PIPELINE SUPERCHARGED BY AI.</h3>
        <h5>Start Free with MapOut</h5>
        <CommonBlackBgButton onClick={scrollToJoinMapout}>Sign Up for Free</CommonBlackBgButton>
      </StyledStartFreeContentWrapper>

      <StyledStartFreeImgWrapper>
        <Lottie animationData={prmptAnimation} />
      </StyledStartFreeImgWrapper>
    </StyledStartFreeContainer>
  );
};

export default StartFree;
