import React from "react";
import "./index.css";
import background from "../../../Assets/Images/EmployersBackground.png";
import Lottie from "lottie-react";
// import { redirectToAppStore } from "../../../Utils/platformCheck";
import Animation from "../../../Assets/lottie/interview.json";

const BigEmployers = () => {
  const handleSignUpClicked = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div
      // className="big-employer-container"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        width: "100wh",
        height: "100vh",
        backgroundPosition: "top center",
        display: "flex",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "baseline",
        }}
      >
        <div style={{ padding: "0 120px" }}>
          <p style={{ color: "#000", fontSize: "30px", fontWeight: "600" }}>
            Discover & Engage with Employers like never before{" "}
          </p>
          <p
            style={{
              color: "#000",
              fontSize: "20px",
              fontWeight: "400",
              marginTop: "32px",
            }}
          >
            Get an inside look like never before by having access to employers
            that want to showcase their company culture and faces behind the
            brand.{" "}
          </p>
          <p
            style={{
              color: "#000",
              fontSize: "20px",
              fontWeight: "400",
              marginBottom: "32px",
              marginTop: "16px",
            }}
          >
            Explore what employers are looking for in candidates, hear directly
            from employees about their experiences, and get a feel for whether
            it's the right fit for you.
          </p>
          <button
            onClick={handleSignUpClicked}
            style={{
              border: "none",
              backgroundColor: "#000",
              color: "#FFF",
              padding: "12px 32px",
              borderRadius: "20px",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Download Now
          </button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          overflow: "hidden",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Lottie
          animationData={Animation}
          loop
        />
      </div>
    </div>
  );
};

export default BigEmployers;
