import React, { useState } from "react";
import background from "../../../Assets/Images/TestimonialsBackground.png";
import Circle from "../../../Assets/Images/TestimonialsCircle.png";
import StaticImg from "../../../Assets/Images/TestimonialStatic.png";
import ArrowLeft from "../../../Assets/Images/arrow_left.png";
import ArrowRight from "../../../Assets/Images/arrow_right.png";

const data = [
  [
    {
      name: "Jeevan George",
      profession: "BCA student",
      desc: "It's a great initiative for users to find a suitable career path for themselves and learn more about their desired career. It's also great that it makes it easy to find jobs related to that field as well.",
    },
    {
      name: "Agninav Bijoy",
      profession: "BBA student",
      desc: "This app cuts short my work by handing out reliable and summarised information along with other tips and tricks. ",
    },
  ],
  [
    {
      name: "Shruthi PG",
      profession: "Educator",
      desc: "My overall opinion on MapOut is, simply amazing. It solves one main issue of not having an application that gives you a day-to-day acknowledgement on career and other professional things. It solves the Job Search problem. Now I don't have to hop onto many websites to search for a job.",
    },
    {
      name: "Jayant Sinha",
      profession: "Fullstack developer",
      desc: "Discovering this career guidance app has been a revelation for me! It's like having a personal career coach in my pocket 24/7. From refining my resume to acing interviews, the app's comprehensive tools and resources have empowered me every step of the way.",
    },
  ],
  [
    {
      name: "Kriti S",
      profession: "BBA student",
      desc: "A right direction to students who don't know what to pursue and a right direction to students how to do if they know what to pursue.",
    },
    {
      name: "Burhanuddin Mustafa Nasikwala",
      profession: "B.Sc student",
      desc: "I believe this is a great app which a great idea. I really liked how the introduction and signing in was. I believe this app has some great potential and it can be successful. I am a big fan of the clean UI. It makes the ability to get information more easy. I really appreciate the work that has been put into this app. It helps the user to learn and grow well.",
    },
  ],
  [
    {
      name: "Basil Choo",
      profession: "Content Strategist",
      desc: "A fun and engaging experience, the test allowed me to fully immerse myself in real life situations and provide responses fitting to my personality. I received an accurate description of my personality and many ideal career paths tailored just for me!",
    },
    {
      name: "Pragati Rai",
      profession: "Product Designer",
      desc: "Working with my career advisor was a game-changer! Their expertise and personalized approach transformed my resume from bland to brilliant. Thanks to their guidance, I landed my dream job in record time. I highly recommend their services to anyone looking to elevate their career.",
    },
  ],
];

const BigTestimonials = () => {
  const [page, setPage] = useState(0);

  const handleNextClicked = () => {
    if (page === data.length - 1) {
      setPage(0);
    } else {
      setPage((page) => page + 1);
    }
  };

  const handlePrevClicked = () => {
    if (page > 0) {
      setPage((page) => page - 1);
    } else {
      setPage(data.length - 1);
    }
  };

  return (
    <div
      className="testimonials-container"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        // minHeight: "100vh",
        backgroundPosition: "center",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
        height : "auto",
        padding : "3rem 0"
      }}
    >
      <div
        style={{
          justifyContent: "center",
          display: "flex",
          position: "realative",
          zIndex: 6,
          marginTop:"1rem"
        }}
      >
        <p
          style={{
            fontWeight: "600",
            zIndex: 8,
            fontSize: 40,
            color: "#000000",
          }}
        >
          What users are saying
        </p>
        <img 
          alt=""
          src={StaticImg}
          style={{
            position: "absolute",
            width: "150px",
            marginTop: "-1.75rem",
            zIndex: 7,
          }}
        />
      </div>

      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "630px",
        }}
      >
        <div
          style={{
            height: "630px",
            width: "630px",
            backgroundImage: `url(${Circle})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: "auto",
            zIndex: 1,
          }}
        ></div>
        <div style={{ display: "flex", zIndex: 2 }}>
          {data[page]?.map((testimonial) => (
            <div
              style={{
                flex: 1,
                marginLeft: 20,
                marginBottom: 20,
                width: "500px",
              }}
            >
              <p style={{ color: "#000000", fontWeight: "400", fontSize: 18 }}>
                {testimonial?.desc}
              </p>
              <p
                style={{
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: 24,
                  marginTop: 20,
                }}
              >
                {testimonial?.name}
              </p>
              <p
                style={{
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: 16,
                  marginTop: 5,
                }}
              >
                {testimonial?.profession}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          position: "absolute",
          bottom: "10%",
          right: "10%",
        }}
      >
        <div
          onClick={handlePrevClicked}
          style={{
            userSelect: "none",
            cursor: "pointer",
            borderRadius: 25,
            height: 50,
            width: 50,
            padding: 10,
            border: "1px solid #000000",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            alt=""
            src={ArrowLeft}
            style={{ height: 12, width: 20, paddingTop: 5, paddingLeft: 5 }}
          />
        </div>
        <div
          onClick={handleNextClicked}
          style={{
            userSelect: "none",
            cursor: "pointer",
            borderRadius: 25,
            height: 50,
            width: 50,
            padding: 10,
            border: "1px solid #000000",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: 10,
          }}
        >
          <img
            alt=""
            src={ArrowRight}
            style={{ height: 12, width: 20, paddingTop: 5, paddingLeft: 5 }}
          />
        </div>
      </div>
    </div>
  );
};

export default BigTestimonials;
