import React from "react";
import "./index.css";
import background from "../../../Assets/Images/ProfileBackground.png";
// import { redirectToAppStore } from "../../../Utils/platformCheck";
import Lottie from "lottie-react";
import Animation from "../../../Assets/lottie/portfolio.json";

const BigProfile = () => {
  const handleSignUpClicked = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        backgroundImage: `url(${background})`,
        overflow: "hidden",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
    >
      <div
        style={{
          flex: 1.5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          paddingLeft: 20,
        }}
      >
        {/* <img
          src={require(`../../../Assets/Gifs/home_1.gif`)}
          alt="Company Logo"
        /> */}
        <Lottie
          animationData={Animation}
          loop
          style={{ height: "85vh", width: "auto" }}
        />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "baseline",
        }}
      >
        <div style={{ padding: "0 50px" }}>
          <p style={{ color: "#000", fontSize: "30px", fontWeight: "600" }}>
            Let's make your first impression your best impression.{" "}
          </p>
          <p
            style={{
              color: "#000",
              fontSize: "20px",
              fontWeight: "400",
              marginTop: "32px",
            }}
          >
            Imagine having a platform that showcases your skills, your
            personality, and the amazing things that make YOU, YOU. That's the
            power of MapOut.{" "}
          </p>
          <p
            style={{
              color: "#000",
              fontSize: "20px",
              fontWeight: "400",
              marginBottom: "32px",
              marginTop: "16px",
            }}
          >
            In today's competitive job market, having a standout profile that
            tells your story, highlights your strengths and qualifications,
            isn't just advantageous; it's essential.
          </p>
          <button
            onClick={handleSignUpClicked}
            style={{
              border: "none",
              backgroundColor: "#000",
              color: "#FFF",
              padding: "12px 32px",
              borderRadius: "20px",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Sign Up for Free
          </button>
        </div>
      </div>
    </div>
  );
};

export default BigProfile;
