import React from "react";
import "./index.css";
import { StyledDiscoverContainer } from "./Discover.styled";
import ArrowRight from "../../../Assets/Icons/arrows/Arrowright.png";
import { useScroll } from "../../../Utils/scrollContext";

const BigDiscover = () => {
  const  { scrollToJoinMapout } = useScroll()
  return (
    <div className="home-container-for-employers ">
      <div className="welcome-animation-for-employers"></div>
      <StyledDiscoverContainer>
        <h2>Discover your next Top Talent.</h2>
        <h6>with AI-driven intelligence</h6>

        <button onClick={scrollToJoinMapout} >
          <span  >Get started for free</span> <img src={ArrowRight} alt="arrow" />{" "}
        </button>
      </StyledDiscoverContainer>
    </div>
  );
};

export default BigDiscover;
