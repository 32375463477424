import styled from "styled-components";

export const JoinUsSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem;
  gap: 1rem;
  background-color: #fff;
`;

export const JoinUsSectionSmallContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem 0rem;
  gap: 1rem;
  background-color: #fff;
`;

export const JoinUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  h6 {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -0.015em;
    text-align: left;

    color: #000;
  }

  p {
    color: #000;
    margin-top: 0.5rem;
    a {
      color: #000;
    }
  }

  button {
    outline: none;
    border: none;
    background-color: #000;
    padding: 15px 20px;
    border-radius: 24px;
    width: 100%;
    color: #fff;
    width: 230px;
    cursor: pointer;
  }
`;

export const JoinUsSmallContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  text-align: center;
  align-items: center;
  color: #000;

  h6 {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -0.015em;
    text-align: left;

    color: #000;
  }

  @media screen and (max-width: 768px) {
    h6{
      font-size: 28px;
      font-weight: 700;
      line-height: 40px;
      text-align: center;
    }

    p{
      font-size: 16px;
      max-width: 75%;
      a{
        color: #000;
      }
    }
  }
`;

export const GalleryContainer = styled.div`
  padding: 2rem 0 8rem 0;
`;

export const GallerySmallContainer = styled.div`
  padding: 3rem 0 2rem 0;
`;

export const GalleryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
