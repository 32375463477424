import React, { useEffect, useRef, useState } from 'react';
import './index.css'

const Text1 = (props) => {
  const { text , isSmall } = props;
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect();
        const isVisibleTrue =
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth);
        setIsVisible((prv) => {
          if (!prv) {
            if (isVisibleTrue) {
              return isVisibleTrue;
            } else {
              return isVisibleTrue;
            }
          } else {
            return prv;
          }
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ height:  isSmall ? '40px' : '80px' }}></div>
      <p
        ref={elementRef}
        style={{
          fontSize:  isSmall ? '32px' : '75px',
          fontWeight: '700',
          textDecoration: 'underline',
          textDecorationThickness: '0.035em',
          background: 'linear-gradient(113.69deg, #6691FF 1.79%, #BEEFA9 46.99%, #C566FF 72.68%)',
          WebkitBackgroundClip: 'text',
          color: 'transparent',
          textDecorationColor: 'currentColor', // Ensure the underline follows the gradient color
        }}
        className={`moving-element ${isVisible ? 'visible' : ''}`}
      >
        {text}
      </p>
    </div>
  );
}

export default Text1;
