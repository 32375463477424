import React from "react";
import left from "../../../../Assets/Icons/arrows/left.png";
import right from "../../../../Assets/Icons/arrows/right.png";

const ArrowButton = ({ className, direction }) => {
  return (
    <button className={className}>
      {direction === "right" ? (
        <img src={right} alt="" width={'16px'} />
      ) : (
        <img src={left} alt="" width={'16px'} />
      )}
    </button>
  );
};

export default ArrowButton;
