import React, { useEffect, useState } from "react";
import "./index.css";
import BigAboutTeamMembers from "./BigAboutTeamMembers";
import SmallAboutTeamMembers from "./SmallAboutTeamMembers";

const AboutTeamMembers = () => {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  const [teamData, setTeamData] = useState(false);

  useEffect(() => {
    fetch("https://dev.api-gateway.mapout.com/mapout-node/api/config/team")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const sortedData = data?.data.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setTeamData(sortedData.filter((item) => item.name !== "Komal Tarar"));
      })
      .catch((err) => {
        // console.log("err :>> ", err);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    const mediaQuery = window.matchMedia("(min-width: 768px)");
    mediaQuery.addEventListener("change", handler);
    return () => {
      mediaQuery.removeEventListener("change", handler);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {matches && teamData ? (
        <BigAboutTeamMembers teamData={teamData} />
      ) : (
        <SmallAboutTeamMembers teamData={teamData} />
      )}
    </>
  );
};

export default AboutTeamMembers;
