import styled from "styled-components";

export const StyledValueAddWrapper = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: rgba(216, 227, 252, 0.35);
  padding: 2rem;
  color: #000;

  gap: 5rem;

  h3 {
    font-size: 40px;
    font-weight: 700;
    line-height: 58.8px;
    letter-spacing: -0.015em;
    text-align: center;
    color: #000;
  }
`;

export const StyledValueAddItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  max-width: 1200px;
  gap: 1rem;
`;

export const StyledValueAddItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  background: linear-gradient(334.72deg, #c1d1fc 0%, #ffffff 55.34%);

  min-height: 397px;
  max-width: 340px;
  border-radius: 20px;

  gap: 1rem;

  h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
  }

  @media screen and (max-width: 768px) {
    min-height: 300px;
    img {
      width: 35px;
    }
    h4 {
      font-size: 18px;
    }
    p {
      font-size: 12px;
      line-height: 20px;
    }
  }
`;

export const StyledSmallValueAddWrapper = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: #000;
  gap: 2rem;
  background: rgba(216, 227, 252, 0.35);
  padding: 4rem 0;

  h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    max-width: 70%;
  }

  @media screen and (max-width: 768px) {
    padding: 2rem 0;
    height: auto;
    gap: 1rem;
  }
`;

export const StyledArrowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 0.5px solid #000;
    background-color: white;
  }
`;
