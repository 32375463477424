import React from "react";
import { Link } from "react-router-dom";

import "./privacypolicy.css";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="privacyPolicy">
        <div>
          <div className="backButton">
            {" "}
            <Link to="/">
              <button> ← Back To Home</button>
            </Link>
          </div>

          <div>
            <p style={{ textAlign: "left" }}>
              <p className="heading">PRIVACY POLICY</p>
              <hr />

              <p className="content">
                At MapOut (sometimes also referred as “we”, “us” or “our”) data
                protection is a matter of trust and your privacy is very
                important to us. We use your personal information only in the
                manner set out in this Privacy Policy. Please read this privacy
                policy to learn more about our information gathering and
                dissemination practices.
              </p>
              <br />
              <p className="content">
                The privacy practices of this statement apply to our services
                available under the domain and sub-domains of www.mapout.com
                (the “Website”) and apply generally to our parent, affiliates,
                subsidiaries or joint venture websites. By visiting the Website,
                you agree to be bound by the terms and conditions of this
                Privacy Policy.
              </p>
              <br />
              <p className="content">
                This Privacy Policy explains how MapOut gathers personal
                information, classified as mandatory or optional as part of the
                normal operation of our services; and uses, discloses and
                protects such information through the Website. This detailed
                privacy policy enables you to take informed decisions in
                dealings with us.
              </p>
              <br />
              <p className="content">
                By registering with MapOut, you acknowledge your acceptance of
                the terms of this Privacy Policy, expressly consent to our use
                and disclosure of your personal information in accordance with
                this Privacy Policy and that any personal information provided
                by you through MapOut is provided under a lawful contract. This
                Privacy Policy is incorporated into and subject to the terms of
                the User Agreement.
              </p>
              <br />
              <p className="content">
                Note: Our privacy policy is subject to change at any time
                without notice. To make sure you are aware of any changes,
                please review this policy periodically.
              </p>

              <div>
                <p className="bold">Your Privacy</p>
                <p className="content">
                  At MapOut, we are extremely proud of our commitment to protect
                  your privacy. We value your trust in us. We work hard to earn
                  your confidence so that you can enthusiastically use the
                  Website and recommend your friends and family to use the
                  Website. Please read the following policy to understand how
                  your personal information will be treated as you make full use
                  of our Website.
                </p>
              </div>

              <div>
                <p className="bold">MapOut Privacy Assurance</p>
                <p className="content">
                  MapOut promises that we will not sell or rent your personal
                  information to third parties for their marketing purposes
                  without your explicit consent. From time to time we may reveal
                  general statistical information about our platform and
                  visitors, such as number of visitors, number and types of
                  services purchased, etc. Your trust and confidence are our
                  highest priority.
                </p>
              </div>

              <div>
                <p className="bold">Information that MapOut collects</p>
                <p className="content">
                  When you use our Website or App, we collect and store your
                  personal information. We gather your personal information in
                  order to record, support and facilitate your participation in
                  the activities you select, track your preferences, to provide
                  you with a customized experience, to notify you of any updated
                  information and new activities and other related functions
                  offered by MapOut, keep you informed about latest content
                  available on the platform, special offers, and other services
                  of MapOut, to assist you with customer service or technical
                  support issues, to follow up with you after your visit, to
                  otherwise support your relationship with MapOut or to prevent
                  fraud and unlawful use. For the aforesaid purpose, we only
                  collect such personal information that we consider relevant to
                  understand you or your interests.
                </p>
                <br />
                <p className="content">
                  In general, you can visit the platform without having to
                  provide your personal information during which time you remain
                  anonymous and at no time can we identify you unless you have
                  an account on the platform and log on with your username and
                  password. Once you provide your personal information, you are
                  no more anonymous to us. To fully use our platform, you will
                  need to register using our online registration form, where you
                  may be required to provide us with your name, email id,
                  password and other personal information as indicated on the
                  forms available on the platform. Further the fields are marked
                  as mandatory or optional. We may automatically track certain
                  information about you based upon your behavior on the Website.
                  We use this information to analyze trends, administer the
                  platform, track a user’s movement and gather broad demographic
                  information, to better understand, protect and serve our
                  users. This information is compiled and analyzed on an
                  aggregated basis. This information may include the URL that
                  you just came from (whether this URL is on our Website or
                  not), which URL you next go to (whether this URL is on our
                  Website or not), your computer browser information, and your
                  IP address.
                </p>
                <br />
                <p className="content">
                  You may log into our Website through your Google or Facebook
                  account. If you do so, you must enter the email address and
                  password that you use for your Google or Facebook account. We
                  will ask that you grant us permission to access and collect
                  your Google or Facebook basic information (this includes your
                  name, profile picture, gender, email address, and any other
                  information you have provided on your Google or Facebook
                  account). If you allow us to have access to this information,
                  then we will have access to this information even if you have
                  chosen not to make that information public. We store the
                  information that we receive from Facebook with other
                  information that we collect from you or receive about you.
                  Facebook controls the information it collects from you. For
                  information about how Facebook may use and disclose your
                  information, including any information you make public, please
                  consult Facebook’s privacy policy. We have no control over how
                  any third party website uses or discloses the personal
                  information it collects about you.
                </p>
                <br />
                <p className="content">
                  We use data collection devices such as “cookies” on certain
                  pages of the Website to help analyze our web page flow,
                  measure promotional effectiveness, and promote trust and
                  safety. “Cookies” are small files which identify your computer
                  to our server as a unique user when you visit certain pages on
                  the Website and they are stored by your internet browser on
                  your computer’s hard drive. We offer certain features that are
                  only available through the use of a “cookie”. We also use
                  cookies to allow you to enter your password less frequently
                  during a session. Cookies can also help us provide information
                  that is targeted to your interests. Most cookies are “session
                  cookies”, meaning that they are automatically deleted from
                  your hard drive at the end of a session. You are always free
                  to decline our cookies if your browser permits, although in
                  that case you may not be able to use full functionality of the
                  Website. We only use cookies for your convenience in using the
                  Website and not for obtaining or using any other information
                  about you. We do not control the use of cookies by third
                  parties.
                </p>
                <br />
                <p className="content">
                  If you choose to leave feedback for other users, we retain
                  this information as necessary to resolve disputes, provide
                  customer support and troubleshoot problems as permitted by
                  law.
                </p>
                <br />
                <p className="content">
                  If you send us personal correspondence, such as emails or
                  letters, or if other users or third parties send us
                  correspondence about your activities or postings on the
                  Website, we may collect such information into a file specific
                  to you.
                </p>
              </div>

              <div>
                <p className="bold">Our use of your information</p>
                <p className="content">
                  We collect your personal information and aggregate information
                  about the use of our Website to better understand your needs
                  and to provide you with a better experience. Specifically, we
                  may use your personal information for any of the following
                  reasons:
                </p>
                <ol>
                  <li>
                    To provide our services to you, including registering you
                    for our services, verifying your identity and authority to
                    use our services, and to otherwise enable you to use our
                    Website;
                  </li>
                  <li>
                    For customer support and to respond to your inquiries;
                  </li>
                  <li>For internal record-keeping purposes;</li>
                  <li>
                    To improve and maintain our platform (for example, we track
                    information based on the choices of your courses; this helps
                    us determine which areas of our platform users like best and
                    areas that we may want to enhance; we also will use for
                    trouble-shooting purposes, where applicable);
                  </li>
                  <li>
                    To periodically send promotional emails to the email address
                    you provide regarding new products from us, special offers
                    from us or other information about us that we think you may
                    find interesting;
                  </li>
                  <li>
                    To contact you via email, telephone, or mail, or, where
                    requested, by text message, to deliver certain services or
                    information you have requested;
                  </li>
                  <li>
                    For our market research purposes, including, but not limited
                    to, the customization of the platform according to your
                    interests;
                  </li>
                  <li>
                    We may use your demographic information (i.e., age, location
                    and other various data) to more effectively provide you the
                    services of your choice on our platform;
                  </li>
                  <li>
                    To resolve disputes, to protect ourselves and other users of
                    our platform, and to enforce our Terms of Use;
                  </li>
                  <li>
                    We also may compare personal information collected through
                    the platform to verify its accuracy with personal
                    information collected from third parties; and
                  </li>
                  <li>
                    We may combine aggregate data with the personal information
                    we collect about you.
                  </li>
                  <li>
                    From time to time, we may use personal information for new
                    and unanticipated uses not previously disclosed in our
                    Privacy Policy. If our information practices change
                    regarding information previously collected, we shall make
                    reasonable efforts to provide notice and obtain consent of
                    any such uses as may be required by law.
                  </li>
                </ol>
              </div>

              <div>
                <p className="bold">Our Disclosure of your information</p>
                <p className="content">
                  Your personal information is an important part of our business
                  and we are not in the business of selling it to others. MapOut
                  shares your personal information only to categories of persons
                  that are either subject to this Privacy Policy or follow
                  practices at least as protective as those described in this
                  Privacy Policy.
                </p>
              </div>

              <div>
                <p className="bold">
                  Items and Services offered on the Platform by third parties
                </p>
                <p className="content">
                  There are certain items and services for which third parties
                  request for information to perform services on our behalf. The
                  information provided by the user for such purposes is
                  simultaneously collected by MapOut and the respective third
                  party. This information is utilized by the third party in
                  accordance with its privacy policy and the terms of the offer.
                  Because we do not control the privacy practices of these third
                  parties, you should evaluate their practices before deciding
                  to use their services.
                </p>
              </div>

              <div>
                <p className="bold">Internal Service Providers</p>
                <p className="content">
                  We employ third party services to facilitate or outsource one
                  or more aspects of the business, product and service
                  operations that we provide to you on the platform and
                  therefore we provide some of your personal information to
                  these service providers. These service providers are bound by
                  legal restrictions that prohibit their use of your personal
                  information for any other purpose except to facilitate the
                  specific outsourced service. In the event of your direct
                  involvement with the internal service provider, any additional
                  information disclosed by you to them shall be subject to the
                  internal service provider’s own applicable privacy policy and
                  the MapOut shall not be responsible for the same.
                </p>
              </div>

              <div>
                <p className="bold">Legal Requests</p>
                <p className="content">
                  We cooperate with law enforcement and regulatory inquiries, as
                  well as other third parties to enforce laws, such as:
                  information technology laws, intellectual property rights,
                  fraud and other rights, to help protect you and the community
                  from bad actors. Therefore, in response to a verified request
                  by law enforcement or other government officials relating to a
                  criminal investigation or alleged illegal activity, we may
                  (and you authorize us to) disclose some of your personal
                  information as is reasonably necessary to respond to court
                  orders, or other legal process. We may disclose personal
                  information to law enforcement offices, third party rights
                  owners, or others in the good faith belief that such
                  disclosure is reasonably necessary to: enforce our Terms of
                  Use or Privacy Policy; respond to claims that an
                  advertisement, posting or other content violates the rights of
                  a third party; or protect the rights, property or personal
                  safety of our users or the general public.
                </p>
              </div>

              <div>
                <p className="bold">Your use of other users’ information</p>
                <p className="content">
                  In order to facilitate interaction among all MapOut members,
                  we may allow you to access other users’ information. Further,
                  as a user you will be sent via email the MapOut registered
                  user ID, email address and other information. By entering into
                  our User Agreement or Terms of Use, you agree that, with
                  respect to other user’s personally identifiable information
                  that you obtain through the Website or through a
                  MapOut-related communication or MapOut - facilitated
                  transaction, MapOut hereby grants to you a license to use such
                  information only for:
                </p>
                <ol>
                  <li>
                    MapOut -related communications that are not unsolicited
                    commercial messages,
                  </li>
                  <li>using co-branded services offered through MapOut, and</li>
                  <li>
                    any other purpose that such user expressly opts into after
                    adequate disclosure of the purpose(s).
                  </li>
                </ol>
                <p className="content">
                  In all cases, you must give users an opportunity to remove
                  themselves from your database and a chance to review what
                  information you have collected about them. In addition, under
                  no circumstances, except as defined in this Section, can you
                  disclose personally identifiable information about another
                  user to any third party without our consent and the consent of
                  such other user after adequate disclosure.
                </p>
                <p className="content">
                  MapOut and our users do not tolerate spam. Therefore, without
                  limiting the foregoing, you are not licensed to add a MapOut
                  user, even a user who has availed a service from you, to your
                  mailing list (email or physical mail) without their express
                  consent after adequate disclosure.
                </p>
              </div>

              <div>
                <p className="bold">What about children’s privacy?</p>
                <p className="content">
                  If you are under the age of 18, you cannot provide or directly
                  avail any services given on the platform. If you wish to
                  provide or avail any services given on the Website such may be
                  made by your legal guardian or parents.
                </p>
              </div>
              <div>
                <p className="bold">
                  Access or change your personally identifiable information
                </p>
                <p className="content">
                  To protect your privacy and security, we will verify your
                  identity before granting access or making changes to your
                  personally identifying information. If you have registered
                  your profile on MapOut, your registered user ID and Password
                  are required in order to access your profile information.
                </p>
              </div>

              <div>
                <p className="bold">Security</p>
                <p className="content">
                  When you access your account information, we offer the use of
                  a secure server. The secure server software (SSL) encrypts all
                  information you put in before it is sent to us. Once your
                  information is in our possession we adhere to strict security
                  guidelines in compliance with the (Indian) Information
                  Technology Act, 2000, protecting it against unauthorized
                  access.
                </p>
                <br />
                <p className="content">
                  We confirm that we have complied with reasonable security
                  practices and procedures as given in Information Technology
                  (Reasonable security practices and procedures and sensitive
                  personal Information) Rules, 2011.
                </p>
                <br />
                <p className="content">
                  We have implemented such security practices and standards and
                  have a comprehensive documented information security programme
                  and information security policies that contain managerial,
                  technical, operational and physical security control measures
                  that are commensurate with the information assets being
                  protected with the nature of business. In the event of an
                  information security breach, we shall be required to
                  demonstrate, as and when called upon to do so by the agency
                  mandated under the law, that they have implemented security
                  control measures as per their documented information security
                  programme and information security policies.
                </p>
              </div>

              <div>
                <p className="bold">Your Consent</p>
                <p className="content">
                  By using the platform and/ or by providing your information,
                  you acknowledge our use of your personal information in the
                  manner as set out in this Privacy Policy. Any changes to our
                  privacy policy will be communicated through our platform.
                </p>
              </div>

              <div>
                <p className="bold">Additional notes to the User</p>
                <p className="content">
                  We do not exercise control over any external content displayed
                  or the links shown as a part of the content of the platform.
                  The other websites may place their own cookies or other files
                  on the users’ computer or mobile device, collect data or
                  solicit personal information from the users, for which we are
                  not responsible or liable. Accordingly, we do not make any
                  representations concerning the privacy practices or policies
                  of such third parties or terms of use of such websites, nor do
                  we guarantee the accuracy, integrity, or quality of the
                  information, data, text, software, sound, photographs,
                  graphics, videos, messages or other materials available on
                  such websites. We encourage the user to read the privacy
                  policies of that website.
                </p>
                <br />
                <p className="content">
                  We shall not be responsible in any manner for the authenticity
                  of the personal information or sensitive personal data or
                  information supplied by the user to us. If a user provides any
                  information that is untrue, inaccurate, not current or
                  incomplete (or becomes untrue, inaccurate, not current or
                  incomplete), or MapOut has reasonable grounds to suspect that
                  such information is untrue, inaccurate, not current or
                  incomplete, we have the right to suspend or terminate such
                  account at its sole discretion.
                </p>
                <br />
                <p className="content">
                  We shall not be responsible for any breach of security or for
                  any actions of any third parties that receive users’ personal
                  data or events that are beyond the reasonable control of
                  MapOut including, acts of government, computer hacking,
                  unauthorized access to computer data and storage device,
                  computer crashes, breach of security and encryption, etc.
                </p>
                <br />
                <p className="content">
                  The user is responsible for maintaining the confidentiality of
                  the user’s account access information and password. The user
                  shall be responsible for all uses of the user’s account and
                  password, whether or not authorized by the user. The user
                  shall immediately notify us of any actual or suspected
                  unauthorized use of the user’s account or password.
                </p>
                <br />
                <p className="content">
                  We will communicate with the users through email and notices
                  posted on the platform or through other means available
                  through the service, including text and other forms of
                  messaging. The users can ask us, by sending an email to
                  support@mapout.com containing the rectification required.
                </p>
              </div>

              <div>
                <p className="bold">Changes in the Privacy Policy</p>
                <p className="content">
                  We may update this Privacy Policy at any time, with or without
                  advance notice. In the event there are significant changes in
                  the way we treat user’s personal information, we will display
                  a notice on the Website or send users an email. If a user uses
                  the service after notice of changes have been sent to such
                  user or published on the Website, such user hereby provides
                  his/ her/ its consent to the changed practices.
                </p>
              </div>

              <div>
                <p className="bold">Grievance Officer:</p>
                <p className="content">
                  MapOut addresses any discrepancies and grievances of all users
                  with respect to processing of information in a time bound
                  manner. For this purpose, MapOut has designated Hina Chopra as
                  the Grievance Officer, who will redress the grievances of the
                  Users expeditiously but within one month from the date of
                  receipt of grievance, and who can be reached by:
                </p>
                <p className="content">
                  Sending an email to support@mapout.com
                </p>
              </div>
              <div style={{ margin: "5rem 0", textAlign: "center" }}>
                <p className="lastContent">
                  This Privacy Policy was most recently updated in November,
                  2023
                </p>
              </div>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
