import React, { useState, useEffect } from "react";
import { saveContactUs } from "../../../Api/contactUs";
import Modal from "../../Common/modal/modal";

const SmallFormPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    queryNature: "",
    queryDetails: ""
  });

  const [errors, setErrors] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const isFormValid = Object.values(formData).every(field => field.trim() !== "");
    setIsSubmitDisabled(!isFormValid);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = "Name is required.";
    if (!formData.email) {
      tempErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Email is invalid.";
    }
    if (!formData.subject) tempErrors.subject = "Subject is required.";
    if (!formData.queryNature) tempErrors.queryNature = "Please select the nature of your query.";
    if (!formData.queryDetails) tempErrors.queryDetails = "Please explain your query in detail.";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const response = await saveContactUs(formData);
        if (response.status === 201) {
          setIsModalOpen(true);
          setFormData({
            name: "",
            email: "",
            subject: "",
            queryNature: "",
            queryDetails: ""
          })
        } else {
          console.error("Failed to submit form:", response);
        }
      } catch (error) {
        console.error("Error during form submission:", error);
      }
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="big-form-page">
      <div className="info-div">
        <p className="heading">Contact Us</p>
        <p className="description">Feel free to reach out to us, we're happy to hear from you anytime!</p>
      </div>
      <div className="form-div">
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="form-label">Name*</label>
            <input
              type="text"
              name="name"
              className="form-input"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <span className="error">{errors.name}</span>}
          </div>
          <div className="form-group">
            <label className="form-label">Email*</label>
            <input
              type="email"
              name="email"
              className="form-input"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
          <div className="form-group">
            <label className="form-label">Subject*</label>
            <input
              type="text"
              name="subject"
              className="form-input"
              value={formData.subject}
              onChange={handleChange}
            />
            {errors.subject && <span className="error">{errors.subject}</span>}
          </div>
          <div className="form-group">
            <label className="form-label">Nature of your query*</label>
            <div className="radio-group">
              <label className="radio-box">
                <input
                  type="radio"
                  name="queryNature"
                  value="Press inquiry"
                  checked={formData.queryNature === "Press inquiry"}
                  onChange={handleChange}
                />
                Press inquiry
              </label>
              <label className="radio-box">
                <input
                  type="radio"
                  name="queryNature"
                  value="General inquiry"
                  checked={formData.queryNature === "General inquiry"}
                  onChange={handleChange}
                />
                General inquiry
              </label>
              <label className="radio-box">
                <input
                  type="radio"
                  name="queryNature"
                  value="Partnership inquiry"
                  checked={formData.queryNature === "Partnership inquiry"}
                  onChange={handleChange}
                />
                Partnership inquiry
              </label>
            </div>
            {errors.queryNature && <span className="error">{errors.queryNature}</span>}
          </div>
          <div className="form-group">
            <label className="form-label">Explain your query in detail*</label>
            <textarea
              name="queryDetails"
              className="form-textarea"
              value={formData.queryDetails}
              onChange={handleChange}
            />
            {errors.queryDetails && <span className="error">{errors.queryDetails}</span>}
          </div>
          <div className="submit-box">
            <button
              type="submit"
              className="submit-button"
              disabled={isSubmitDisabled}
              style={{ backgroundColor: isSubmitDisabled ? "#7F8A8E" : "#000" }}
            >
              Send
            </button>
          </div>
        </form>
      </div>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <p>Your query is successfully submitted. Thank you, someone will reach out to you soon.</p>
      </Modal>
    </div>
  );
};

export default SmallFormPage;
