import React from "react";
import './index.css'

const BigAboutMapout = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        background: 'linear-gradient(113.69deg, rgba(255, 255, 255, 0.75) 4.65%, rgba(227, 255, 215, 0.75) 20.31%, rgba(216, 227, 252, 0.75) 46.64%, rgba(233, 217, 255, 0.75) 81.94%)',
        backgroundSize: "cover",
        backgroundPosition: "top center",
        overflow: 'hidden'
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
        }}
      >
        <div style={{ display: "flex", flex: 1.5, justifyContent: 'center', marginBottom:'120px', flexDirection: 'column' }}>
            <p style={{fontSize:'75px', fontWeight: '700', color: '#000', padding:'0 64px', lineHeight: '90px'}}>We merge technology with</p>
            {/* <p style={{fontSize:'75px', fontWeight: '700', color: '#000', padding:'0 64px', lineHeight: '90px'}}>career wisdom.</p> */}
            <div style={{alignSelf: 'baseline', }}>
              <p className="type-animation" style={{ backgroundColor: 'rgba(255,255,255,0.5)', alignSelf: 'baseline', borderRadius:'12px' }}>career wisdom.</p>
            </div>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            height: "100%",
            width: "100%",
            borderTopLeftRadius: "50px",
            borderBottomLeftRadius: "50px",
            alignItems:'center',
            justifyContent:'center',
            backgroundColor: 'rgba(255, 255, 255, 0.85)'
          }}
        >
            <p style={{fontSize: '30px',fontWeight: '600', color: '#000', padding:'0 32px 32px 100px', textAlign:'center'}}>We want to ensure that everyone striving for a successful career finds their perfect fit.</p>
        </div>
      </div>
    </div>
  );
};

export default BigAboutMapout;
