import React, { useEffect, useState } from "react";
import "./index.css";
import SmallFormPage from "./smallScreen";
import BigFormPage from "./bigScreen";

const ContactUsMainPage = () => {
    
  const [matches, setMatches] = useState(window.matchMedia("(min-width: 768px)").matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    const mediaQuery = window.matchMedia("(min-width: 768px)");
    mediaQuery.addEventListener('change', handler);
    return () => {
      mediaQuery.removeEventListener('change', handler);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {matches ? (<BigFormPage/>) : (<SmallFormPage />)}
    </>
  );
};

export default ContactUsMainPage;
