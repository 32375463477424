import React from "react";
import {
  StyledTalentPowerFeaturesItem,
  StyledTalentPowerFeaturesWrapper,
  StyledTalentPowerHouseContainer,
  StyledTalentPowerSlideContentWrapper,
  StyledTalentPowerSliderItem,
} from "./TalentPowerHouse.styled";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import { Navigation, Autoplay } from "swiper/modules";

import image1 from "../../../Assets/Images/EmployerPowerHouseImages/1.png";
// import image2 from "../../../Assets/Images/EmployerPowerHouseImages/2.png";
// import image3 from "../../../Assets/Images/EmployerPowerHouseImages/3.png";
import image4 from "../../../Assets/Images/EmployerPowerHouseImages/4.png";
import image5 from "../../../Assets/Images/EmployerPowerHouseImages/5.png";
import image6 from "../../../Assets/Images/EmployerPowerHouseImages/6.png";

import ArrowButton from "../../Common/Utils/Buttons/ArrowButton";

const TalentPowerHouse = () => {
  const powerHouseItems = [
    {
      id: 1,
      title: "Showcase your company culture like an asset!",
      img: image1,
    },
    {
      id: 2,
      title: "Engaging job role postings that attract the best talent.",
      img: image5,
    },
    {
      id: 3,
      title: "Build 24/7/365 engagement with talent.",
      img: image6,
    },
    {
      id: 4,
      title: "Compare talent to shortlist the best.",
      img: image4,
    },
  ];

  const featuresItem = [
    {
      id: 1,
      title: "Access",
      desc: "Identify exceptional talent seamlessly with AI-driven innovation.",
      borderColor: "#D9BCFF",
    },
    {
      id: 2,
      title: "Evaluate",
      desc: "Go beyond resumes to discover true potential and assess fit accurately.",
      borderColor: "#B9E4A6",
    },
    {
      id: 3,
      title: "Connect",
      desc: "Engage with top talent by conversing with driven and proactive individuals.",
      borderColor: "#6691FF",
    },
  ];

  return (
    <StyledTalentPowerHouseContainer>
      <h2>Welcome to your Talent Acquisition Powerhouse!</h2>

      <StyledTalentPowerSlideContentWrapper>
        <ArrowButton className="powerhouse-slider-left" direction="left" />
        <Swiper
          cssMode={true}
          modules={[Navigation, Autoplay]}
          centeredSlides={true}
          navigation={{
            nextEl: ".powerhouse-slider-right",
            prevEl: ".powerhouse-slider-left",
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          className="mySwiper-employer-powerhouse"
        >
          {powerHouseItems.map((item) => {
            return (
              <SwiperSlide>
                <StyledTalentPowerSliderItem>
                  <div>
                    <img src={item.img} alt="" />
                  </div>
                  <p>{item.title}</p>
                </StyledTalentPowerSliderItem>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <ArrowButton className="powerhouse-slider-right" direction="right" />
      </StyledTalentPowerSlideContentWrapper>

      <StyledTalentPowerFeaturesWrapper>
        {featuresItem.map((item) => {
          return (
            <StyledTalentPowerFeaturesItem
              key={item.id}
              borderColor={item.borderColor}
            >
              <h6>{item.title}</h6>
              <p>{item.desc}</p>
            </StyledTalentPowerFeaturesItem>
          );
        })}
      </StyledTalentPowerFeaturesWrapper>
    </StyledTalentPowerHouseContainer>
  );
};

export default TalentPowerHouse;
