import React, { useEffect, useState } from "react";
import {
  StyledJobItemsContainer,
  StyledJobItemsContents,
  StyledjobItemWrapper,
  StyledJobOpeningsContainer,
} from "./JobOpenings.styled";
import chevronRight from "../../../Assets/Icons/chevron/left.png";
import { useNavigate } from "react-router-dom";
import { getAllOpenPositions } from "../../../Api/job";
import SyncLoader from "react-spinners/SyncLoader";

const JobOpenings = () => {
  const [allOpenPositions, setallOpenPositions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setpenPositions();
  }, []);

  const setpenPositions = async () => {
    setLoading(true);
    const { data } = await getAllOpenPositions();
    console.log("resss >> ", data?.openPostions);
    setallOpenPositions(data?.openPostions);
    setLoading(false);
  };

  const navigate = useNavigate();

  return (
    <StyledJobOpeningsContainer>
      <h3>Join our team</h3>
      <p>
        Are you passionate about empowering the next generation of talent? Do
        you thrive in a fast-paced, innovative environment? If so, we want to
        hear from you!
      </p>

      <StyledJobItemsContainer>
        {loading ? (
          <SyncLoader
            color={"#000"}
            loading={loading}
            size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : allOpenPositions.length > 0 ? (
          allOpenPositions.map((item) => {
            return (
              <StyledjobItemWrapper
                key={item._id}
                onClick={() => navigate(`/open-positions/${item._id}`)}
              >
                <StyledJobItemsContents>
                  <h4>{item.title}</h4>
                  <div>
                    <p>{item.location}</p>
                    <span>•</span>
                    <p>{item.workMode}</p>
                    <span>•</span>
                    <p>{item.type}</p>
                    <span>•</span>
                    <p>{item.experience}</p>
                  </div>
                </StyledJobItemsContents>
                <img src={chevronRight} alt="chevron right" />
              </StyledjobItemWrapper>
            );
          })
        ) : (
          <p>No Data Found</p>
        )}
      </StyledJobItemsContainer>

      <span>
        We encourage applications from all qualified candidates. MapOut is an
        equal opportunity employer and values diversity at our company. We do
        not discriminate on the basis of race, religion, color, national origin,
        gender, sexual orientation, age, marital status, veteran status, or
        disability status.
      </span>
    </StyledJobOpeningsContainer>
  );
};

export default JobOpenings;
