import React, { useEffect } from "react";
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import ArrowLeft from "../../../Assets/Images/arrow_left.png";
import ArrowRight from "../../../Assets/Images/arrow_right.png";
import background from "../../../Assets/Images/TestimonialsBackground.png";
import StaticImg from "../../../Assets/Images/TestimonialStatic.png";

import "./smallScreen.css";

const data = [
    {
      name: "Jeevan George",
      profession: "BCA student",
      desc: "It's a great initiative for users to find a suitable career path for themselves and learn more about their desired career. It's also great that it makes it easy to find jobs related to that field as well.",
    },
    {
      name: "Agninav Bijoy",
      profession: "BBA student",
      desc: "This app cuts short my work by handing out reliable and summarised information along with other tips and tricks. ",
    },
    {
      name: "Shruthi PG",
      profession: "Educator",
      desc: "My overall opinion on MapOut is, simply amazing. It solves one main issue of not having an application that gives you a day-to-day acknowledgement on career and other professional things. It solves the Job Search problem. Now I don't have to hop onto many websites to search for a job.",
    },
    {
      name: "Jayant Sinha",
      profession: "Fullstack developer",
      desc: "Discovering this career guidance app has been a revelation for me! It's like having a personal career coach in my pocket 24/7. From refining my resume to acing interviews, the app's comprehensive tools and resources have empowered me every step of the way.",
    },
    {
      name: "Kriti S",
      profession: "BBA student",
      desc: "A right direction to students who don't know what to pursue and a right direction to students how to do if they know what to pursue.",
    },
    {
      name: "Burhanuddin Mustafa Nasikwala",
      profession: "B.Sc student",
      desc: "I believe this is a great app which a great idea. I really liked how the introduction and signing in was. I believe this app has some great potential and it can be successful. I am a big fan of the clean UI. It makes the ability to get information more easy. I really appreciate the work that has been put into this app. It helps the user to learn and grow well.",
    },
    {
      name: "Basil Choo",
      profession: "Content Strategist",
      desc: "A fun and engaging experience, the test allowed me to fully immerse myself in real life situations and provide responses fitting to my personality. I received an accurate description of my personality and many ideal career paths tailored just for me!",
    },
    {
      name: "Pragati Rai",
      profession: "Product Designer",
      desc: "Working with my career advisor was a game-changer! Their expertise and personalized approach transformed my resume from bland to brilliant. Thanks to their guidance, I landed my dream job in record time. I highly recommend their services to anyone looking to elevate their career.",
    }
];

const BigTestimonials = () => {
  useEffect(() => {
    const swiper = new Swiper(".swiper-item", {
      modules: [Navigation, Pagination],
      direction: "horizontal",
      loop: true,
      pagination: {
        el: ".swiper-pagination",
      },
      spaceBetween: 5,
      slidesPerView: 2,
      centeredSlides: true,

      navigation: {
        nextEl: ".swiper-button-next__",
        prevEl: ".swiper-button-prev__",
      },

      scrollbar: {
        el: ".swiper-scrollbar",
      },
    });
    return () => {
      swiper.destroy();
    };
  }, []);

  return (
    <div
      className="testimonials-container"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        minHeight: "100vh",
        backgroundPosition: "center",
        display: "flex",
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "3rem",
        position: "relative",
      }}
    >
      <div
        style={{
          justifyContent: "center",
          marginBottom: "5vh",
          display: "flex",
          position: "relative",
        }}
      >
        <p
          style={{
            fontWeight: "600",
            zIndex: 2,
            fontSize: 21,
            color: "#000000",
          }}
        >
          What users are saying
        </p>
        <img
          alt=""
          src={StaticImg}
          style={{
            position: "absolute",
            width: "70px",
            marginTop: "-0.75rem",
            zIndex: 1,
          }}
        />
      </div>
      <div className="testimonilas-small-circle-container">
        <div class="swiper-item" >
          <div
            class="swiper-wrapper"
            style={{  zIndex: 10 }}
          >
            {data.map((e) => (
              <div class="swiper-slide">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: 12,
                    background: "#FFFFFF",
                    alignItems: "center",
                    overflow: "hidden",
                    padding: "0.75rem",
                    minHeight: "226px",
                    textAlign : "center",
                  }}
                >
                  <p
                    style={{
                      color: "#000000",
                      fontWeight: "600",
                      fontSize: 14,
                      marginTop: 10,
                    }}
                  >
                    {e.name}
                  </p>
                  <p
                    style={{
                      color: "#000000",
                      fontWeight: "500",
                      fontSize: 11,
                    }}
                  >
                    {e.profession}
                  </p>
                  <p
                    style={{
                      color: "#000000",
                      fontWeight: "400",
                      fontSize: 11,
                      marginTop: 20,
                      marginLeft: 5,
                      marginRight: 5,
                    }}
                  >
                    {e?.desc}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div style={{ position: "absolute", bottom: "5vh", right: "5vh" }}>
        <div style={{ display: "flex" }}>
          <div
            class="swiper-button-prev__"
            style={{
              userSelect: "none",
              cursor: "pointer",
              borderRadius: 25,
              height: 50,
              width: 50,
              padding: 10,
              border: "1px solid #000000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt=""
              src={ArrowLeft}
              style={{ height: 12, width: 20, paddingTop: 5, paddingLeft: 5 }}
            />
          </div>
          <div
            class="swiper-button-next__"
            style={{
              userSelect: "none",
              cursor: "pointer",
              borderRadius: 25,
              height: 50,
              width: 50,
              padding: 10,
              border: "1px solid #000000",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: 10,
            }}
          >
            <img
              alt=""
              src={ArrowRight}
              style={{ height: 12, width: 20, paddingTop: 5, paddingLeft: 5 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BigTestimonials;
