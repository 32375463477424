import React, { useRef, useState } from "react";
import styled from "styled-components";
import close from "../../../Assets/Icons/close.png";

const UploadComponent = ({ onFileChange }) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onFileChange(file);
      setUploadedFile(file);
    }
  };

  const handleRemove = (event) => {
    event.stopPropagation();
    setUploadedFile(null);
    fileInputRef.current.value = null;
  };

  return (
    <StyledInputUploadContainer
      onClick={handleClick}
      uploadedFile={uploadedFile}
    >
      {uploadedFile ? (
        <FileInfo>
          <FileName>
            <FileIcon>📄</FileIcon>
            {uploadedFile.name}
          </FileName>
          <FileSize>
            {(uploadedFile.size / (1024 * 1024)).toFixed(1)}MB
          </FileSize>
          <RemoveButton onClick={handleRemove}>
            <img src={close} alt="" />
          </RemoveButton>
        </FileInfo>
      ) : (
        <>
          <UploadText>Upload</UploadText>
          <UploadSubText>
            Suitable formats: PDF. No more than 5MB.
          </UploadSubText>
        </>
      )}
      <StyledInputUpload
        type="file"
        accept=".pdf"
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </StyledInputUploadContainer>
  );
};

const StyledInputUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px;
  border: 2px dashed #b3b3b3;
  border-radius: 8px;
  background-color: #ffffff;
  cursor: pointer;
  color: #333;
  text-align: center;

  &:hover {
    background-color: ${({ uploadedFile }) =>
      uploadedFile ? "#ffffff" : "#f2f2f2"};
  }
`;

const StyledInputUpload = styled.input`
  display: none;
`;

const UploadText = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const UploadSubText = styled.div`
  font-size: 14px;
  color: #999;
`;

const FileInfo = styled.div`
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 8px;
  width: 100%;
  justify-content: space-between;
`;

const FileName = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const FileIcon = styled.div`
  margin-right: 8px;
  font-size: 20px;
`;

const FileSize = styled.div`
  font-size: 12px;
  color: #666;
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
  font-size: 16px;
  margin-left: 8px;
  max-width: 35px;
  max-height: 35px;

  &:hover {
    color: #666;
  }
`;

export default UploadComponent;
