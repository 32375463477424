import styled from "styled-components";

export const StyledJoinMapoutWrapper = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 2rem;
  color: #000;
  padding: 2.5rem;
  padding-left: 4rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 4rem 1rem;
  }
`;

export const StyledJoinMapoutConentWrapper = styled.div`
  flex: 1;
  max-width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
  h3 {
    font-size: 40px;
    font-weight: 700;
    line-height: 58.8px;
    letter-spacing: -0.015em;
    text-align: left;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }

  @media screen and (max-width: 768px) {
    align-items: center;
    max-width: 90%;

    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
    }
    p {
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
    }
  }
`;

export const StyledJoinMapoutFormWrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledJoinMapoutFormContainer = styled.form`
  background: linear-gradient(
    113.69deg,
    #e2d6f4 5.48%,
    #d8e3fc 61.66%,
    #e3d5f3 93.9%
  );
  padding: 2rem;
  max-width: 562px;
  min-height: 572px;
  border-radius: 20px;
  width: 100%;

  button {
    margin-top: 1rem;
  }
  h6 {
    font-family: Manrope;
    font-size: 22px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
  }
`;

export const StyledFormInputItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  margin-top: 1rem;
  width: 100%;

  label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }

  input {
    width: 100%;
    min-height: 33px;
    border-radius: 8px;
    border: 1px solid rgba(222, 226, 227, 1);
    outline: 0;
    padding: 12px;
  }

  textarea {
    width: 100%;
    padding: 6px 0;
    outline: 0;
    min-height: 33px;
    border-radius: 8px;
    border: 1px solid rgba(222, 226, 227, 1);
    padding: 12px;

  }

  & > div {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 5px;
  }

  select {
    width: 100%;
    min-height: 33px;
    height: 100%;
    border-radius: 8px;
    border: 1px solid rgba(222, 226, 227, 1);
    outline: 0;
    max-width: 80px;
    padding: 12px 4px;
  }
`;

export const StyledFormInputMultipleItemWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;

  & > :nth-child(1){
    flex: 1;
  }
  & > :nth-child(2){
    flex: 1.5;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const StyledErrorText = styled.p`
color: red;
`;
export const StyledSuccessText = styled.p`
color: green;
margin-top: 1rem;
`;
