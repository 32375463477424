import { api } from "./config";

export const getAllOpenPositions = async (id) => {
  try {
    const url = id ? `open-positions?id=${id}` : "open-positions";
    const res = await api.get(url);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const applyToJobWithId = async (data) => {
  try {  
    const res = await api.post(`/open-positions/apply`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'uniqueid' : "uniqueIdForApplication"
      },
    });
    return res;
  } catch (error) {
    return error.response;
  }
};
