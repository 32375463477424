import styled from "styled-components";
export const StyledStartFreeContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    107.79deg,
    #ffffff 18.91%,
    #d8e3fc 71.42%,
    rgba(185, 228, 166, 0.2) 98.71%
  );

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 2rem;
    padding: 4rem 1.5rem;
  }
`;

export const StyledStartFreeContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: left;
  padding-left: 1%;

  color: #000;

  h3 {
    font-size: 38px;
    font-weight: 700;
    line-height: 50px;
    text-align: left;
    max-width: 95%;
  }

  h5 {
    font-size: 38px;
    font-weight: 700;
    line-height: 50px;
    text-align: left;
    margin-top: 3rem;
  }

  button {
    margin-top: 4rem;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
    align-items: center;
    padding-left: 0;

    h3 {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      margin-top: 3rem;
    }

    h5 {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }
  }
`;

export const StyledStartFreeImgWrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    max-width: 512px;
    max-height: 559px;
  }
`;
