import styled from "styled-components";

export const StyledJobDetailsContainer = styled.div`
  width: 100%;
  height: auto;

  background: linear-gradient(
    113.69deg,
    #ffffff 3.27%,
    #e2d6f4 62.22%,
    #d8e3fc 93.9%
  );

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000000;
  padding: 10rem 3rem;
  gap: 1rem;

  & > p {
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    padding: 5rem 1rem;
  }
`;

export const StyledJobDetailsHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & > div > img {
    width: 35px;
    height: 35px;
  }

  & > div {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    & > div > img {
      width: 25px;
      height: 25px;
      margin-top: 10px;
    }
  }
`;

export const StyledApplyButton = styled.button`
  padding: 12px 48px;
  gap: 10px;
  border-radius: 20px;
  background-color: #000;
  color: #fff;
  border: 0;
  outline: 0;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 90%;
    border-radius: 12px;
    margin-top: 1rem;
    font-size: 14px;
    align-self: center;
  }
`;
export const StyledJobDetailsHeaderItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  @media screen and (max-width: 768px) {
    & > div {
      gap: 5px;
      flex-wrap: wrap;
    }

    & > h3 {
      font-size: 16px;
      font-weight: 600;
      line-height: 44px;
      text-align: left;
    }

    & > div > p {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
    }
  }
`;

export const StyledJobDetailsJobDetailsContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 24px;

  & > div > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    margin-left: 1rem;
  }

  @media screen and (max-width: 768px) {
    background-color: rgba(255, 255, 255, 0.5);

    & > div > p {
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      margin-left: 1rem;
    }
  }
`;

export const StyledJobDeatilsItemsTitle = styled.div`
  padding: 8px 30px 8px 30px;
  gap: 10px;
  border-radius: 40px;
  border: 0.25px;
  background: #d9bcff;
  max-width: 210px;
  text-align: center;
  margin: 1rem 0;
`;

export const StyledJobDetailsUnOrderdList = styled.ul`
  padding: 0 2rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;

  @media screen and (max-width: 768px) {
    padding: 0 1rem;

    & > li {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      text-align: left;
    }
  }
`;

export const StyledJobDetailsOrderdList = styled.ol`
  padding: 0 2rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;

  @media screen and (max-width: 768px) {
    padding: 0 1rem;

    & > li {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      text-align: left;
    }
  }
`;

export const StyledJobDetailsBonus = styled.div`
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;

  div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    :nth-last-child(2) {
      margin-top: 2rem;
    }
    :last-child {
      margin-top: 1rem;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 1rem 0;
    font-size: 12px;
  }
`;

export const StyledJobDetailsText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 2rem;

  @media screen and (max-width: 768px) {
    & > p {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      text-align: left;
    }
  }
`;

// Application Form

export const StyledApplicationFormContainer = styled.div`
  display: flex;
  padding: 10rem 3rem;

  align-items: flex-start;
  background: linear-gradient(
    299.13deg,
    rgba(193, 209, 252, 0.45) 21.88%,
    rgba(233, 239, 254, 0.803383) 53.68%,
    #ffffff 71.37%
  );

  @media screen and (max-width: 768px) {
    padding: 5rem 2rem;
    flex-direction: column;
  }
`;

export const StyledApplicationFormHeader = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  color: #000;
  flex: 1;


  img {
    width: 35px;
    height: 35px;
    cursor: pointer;
    margin-top: 5px;
  }

  span {
    font-size: 24px;
    font-weight: 400;
    line-height: 44px;
    text-align: left;
  }

  @media screen and (max-width: 768px) {
    gap: 5px;

    img {
      width: 25px;
      height: 25px;

      margin-left: -1.5rem;
      margin-top: 0px;
    }
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
      text-align: left;
    }
  }
`;

export const StyledApplicationFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  flex: 1;
  width: 100%;

  & > button {
    background-color: #000;
    color: #fff;
    padding: 12px 48px;
    gap: 10px;
    border-radius: 20px;
    border: 0;
    outline: 0;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    margin-top: 1rem;
    & > button {
      width: 80%;
      border-radius: 12px;
      margin-top: 1rem;
    }
  }
`;
export const StyledApplicationFormItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  color: #000;
  max-width: 500px;
  width: 100%;

  label {
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
  }

  input {
    width: 100%;
    padding: 8px;
    border: 0.2px solid #f2f2f2;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #fff;
    outline: none;
    border-radius: 8px;
  }

  textarea {
    width: 100%;
    padding: 8px;
    border: 0.2px solid #f2f2f2;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #fff;
    outline: none;
    border-radius: 8px;
  }

  div {
    display: flex;
    gap: 5px;
  }
`;

export const StyledApplicationFormMultipleItemWrapper = styled.div`
  color: #000;
  display: flex;
  align-items: center;
  max-width: 500px;
  width: 100%;
  gap: 5px;

  input {
    width: 100%;
    padding: 8px;
    border: 0.2px solid #f2f2f2;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #fff;
    outline: none;
    border-radius: 8px;
  }

  select {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 0.2px solid #f2f2f2;
    outline: 0;
    max-width: 90px;
    padding: 8px;
  }

  :nth-child(1) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const StyledErrorText = styled.p`
  color: red;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding: 0.3rem;
  margin: 0;
`;

export const StyledApplicationCompletedPopupWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.29); // Semi-transparent black
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto; // Ensure it captures clicks
`;

export const StyledApplicationCompletedPopup = styled.div`
  background-color: #fff;
  width: 30vw;
  height: 30vh;
  border-radius: 24px;
  position: relative;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  img {
    width: 35px;
    height: 35px;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }

  p {
    color: #000;
  }

  @media screen and (max-width: 768px) {
    width: 70vw;
  }
`;
