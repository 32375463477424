import React from "react";
import "./index.css";
import FooterLogo from "../../Assets/Icons/Mapout-footer-logo.svg";
import LinkedinIcon from "../../Assets/Icons/Footer-LinkedIn.svg";
import InstagramIcon from "../../Assets/Icons/Footer-Instagram.svg";
import { useNavigate } from "react-router-dom";

const SmallFooter = () => {
  const navigate = useNavigate();

  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-top">
          <div className="footer-logo" onClick={() => navigate("/")}>
            <img className="footer-logo-img" src={FooterLogo} alt="" />
          </div>
          <p className="footer-tagline">Your Complete Career Ecosystem</p>
        </div>
        <div className="footer-mid">
          <p className="footer-link" onClick={() => navigate("/")}>
            For Talent
          </p>
          <p className="footer-link" onClick={() => navigate("/employers")}>
            For Employers
          </p>
          <p className="footer-link" onClick={() => navigate("/about-us")}>
            About Us
          </p>
          <p
            className="footer-link"
            onClick={() => navigate("/open-positions")}
          >
            Open Positions
          </p>
            <button
              onClick={() => {
                navigate("/contact-us");
              }}
              style={{ textDecoration: "none", color: "#FFF"  , border : "0" , outline : "none" , backgroundColor : "transparent" , alignSelf : "flex-start" }}
            >
              Contact Us
            </button>
          <div className="footer-social-container">
            <img src={LinkedinIcon} alt="" className="footer-social-icon" />
            <img className="footer-social-icon" src={InstagramIcon} alt="" />
          </div>
        </div>

        <div className="footer-separator"></div>
        <div className="footer-bottom">
          <p className="footer-copyright">
            Copyright © 2024. All rights reserved.
          </p>
          <div className="footer-legal-links">
            <p
              onClick={() => navigate("/termsOfUse")}
              className="footer-legal-link"
            >
              Terms & conditions
            </p>
            <p className="footer-link-divider">|</p>
            <p
              onClick={() => navigate("/privacyPolicy")}
              className="footer-legal-link"
            >
              Privacy policy
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default SmallFooter;
