import styled from "styled-components";

export const StyledDiversityAndInclusionContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 1);
  padding:  0 4rem;
  gap: 2rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 4rem 1rem;
    height: auto;
    color: #000;

    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      max-width: 70%;
    }
  }
`;

export const StyledDiversityImgWrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    max-width: 643px;
    max-height: 395px;
  }

`;

export const StyledDiversityContentWrapper = styled.div`
  flex: 1;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  height: 100%;

  h3 {
    font-size: 38px;
    font-weight: 700;
    line-height: 50px;
    text-align: left;
    max-width: 90%;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    margin-top: 1rem;
  }

  button {
    margin-top: 1rem;
  }

  @media screen and (max-width: 768px) {
    align-items: center;
    p{
font-size: 12px;
font-weight: 500;
line-height: 20px;
text-align: center;
margin-top: 1rem;


    }
  }

`;
