import React, { useEffect, useState } from "react";
import {
  StyledFeaturesOverveiwContainer,
  StyledFeaturesOverveiwItemsContianer,
  StyledFeaturesOverveiwItemsWrapper,
  StyledFeaturesOverveiwText,
  StyledSwiperButton,
  StyledSwiperButtonContainer,
} from "./FeaturesOverView.styled";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import { Navigation } from "swiper/modules";

import submitAssignmentLottie from "../../../Assets/lottie/submitassignment.json";
import coachAnimation from "../../../Assets/lottie/coachuser.json";
import talentTribeAnimation from "../../../Assets/lottie/talentTribe.json";

import ArrowLeft from "../../../Assets/Images/arrow_left.png";
import ArrowRight from "../../../Assets/Images/arrow_right.png";
import Lottie from "lottie-react";

const FeaturesOverView = () => {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    const mediaQuery = window.matchMedia("(min-width: 768px)");
    mediaQuery.addEventListener("change", handler);
    return () => {
      mediaQuery.removeEventListener("change", handler);
    };
  }, []);

  const featuresOverViewItems = [
    {
      animation: submitAssignmentLottie,
      title: "Career Tasters",
      description:
        "Discover your path with Virtual Experience Programs anytime, anywhere.",
    },
    {
      animation: coachAnimation,
      title: "Career Coaching",
      description:
        "Tap into your true potential with personalized AI powered career coaching.",
    },
    {
      animation: talentTribeAnimation,
      title: "Talent Tribe",
      description:
        "Go beyond your Degree and GPA, showcase your talent and personality.",
    },
  ];

  return (
    <StyledFeaturesOverveiwContainer matches={matches}>
      <StyledFeaturesOverveiwText matches={matches}>
        Making career planning: simple, accessible and fun.
      </StyledFeaturesOverveiwText>
      {matches ? (
        <StyledFeaturesOverveiwItemsContianer>
          {featuresOverViewItems.map((item, index) => (
            <StyledFeaturesOverveiwItemsWrapper>
              {item.animation ? (
                <div style={{ borderRadius: "30px", overflow: "hidden" }}>
                  <Lottie animationData={item.animation} />
                </div>
              ) : (
                <div>
                  <img src={item.thumbnail} alt={item.title} />
                </div>
              )}
              <h6>{item.title}</h6>
              <p>{item.description}</p>
            </StyledFeaturesOverveiwItemsWrapper>
          ))}
        </StyledFeaturesOverveiwItemsContianer>
      ) : (
        <>
          <Swiper
            slidesPerView={1.4}
            centeredSlides={true}
            spaceBetween={0}
            modules={[Navigation]}
            className="mySwiper"
            style={{ width: "100%", height: "auto" }}
            navigation={{
              nextEl: ".swiper-next",
              prevEl: ".swiper-prev",
            }}
          >
            <StyledFeaturesOverveiwItemsContianer matches={matches}>
              {featuresOverViewItems.map((item, index) => (
                <SwiperSlide>
                  <StyledFeaturesOverveiwItemsWrapper matches={matches}>
                    {item.animation ? (
                      <div style={{ borderRadius: "30px", overflow: "hidden" }}>
                        <Lottie animationData={item.animation} />
                      </div>
                    ) : (
                      <div>
                        <img src={item.thumbnail} alt={item.title} />
                      </div>
                    )}
                    <h6>{item.title}</h6>
                    <p>{item.description}</p>
                  </StyledFeaturesOverveiwItemsWrapper>
                </SwiperSlide>
              ))}
            </StyledFeaturesOverveiwItemsContianer>
          </Swiper>

          <StyledSwiperButtonContainer>
            <StyledSwiperButton className="swiper-prev">
              <img src={ArrowLeft} alt="" />
            </StyledSwiperButton>
            <StyledSwiperButton className="swiper-next">
              <img src={ArrowRight} alt="" />
            </StyledSwiperButton>
          </StyledSwiperButtonContainer>
        </>
      )}
    </StyledFeaturesOverveiwContainer>
  );
};

export default FeaturesOverView;
