import styled, { keyframes } from "styled-components";

const slide = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

export const StyledCollegeHeadingText = styled.h4`
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin-bottom: 0.5rem;
`;

export const StyledCollegeWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  border: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  flex-direction: column;
  overflow: hidden;
`;

export const StyledLogoSlideContainer = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledLogoSlideWrapper = styled.div`
  animation: ${slide} 45s infinite linear;
  display: flex;
  align-items: center;
`;

export const StyledCollegeLogo = styled.img`
  margin: 0 40px;
`;
