import React from "react";
import {
  GalleryContainer,
  GalleryImage,
  JoinUsContainer,
  JoinUsSectionContainer,
} from "./bigScreen.styled";
import galleryImageItem from "../../../Assets/Images/galleryImageNew.png";

const BigJoinUs = () => {

  return (
    <>
      <JoinUsSectionContainer>
        <JoinUsContainer>
          <h6>Join our journey</h6>
          <h6>of making career planning simple, accessible and fun.</h6>

          <p>
            Looking to join us? Send your cv to{" "}
            <a href="mailto:hina@mapout.com"> hina@mapout.com </a> even if you
            don't see a specific role!
          </p>
        </JoinUsContainer>
      </JoinUsSectionContainer>
      <GalleryContainer>
        <GalleryImage src={galleryImageItem} />
      </GalleryContainer>
    </>
  );
};

export default BigJoinUs;
