import styled from "styled-components";

export const StyledFeaturesOverveiwContainer = styled.div`
  width: 100%;
  height: ${({ matches }) => (!matches ? "auto" : "100vh")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ matches }) => (!matches ? "2rem" : "4rem")};
  padding: ${({ matches }) => (!matches ? "2rem 0" : "0")};
`;

export const StyledFeaturesOverveiwText = styled.h5`
  font-size: ${({ matches }) => (matches ? "2.5rem" : "1.5rem")};
  font-weight: 700;
  letter-spacing: -0.015em;
  color: #000;
  text-align: center;
  padding: 0 2rem;
`;

export const StyledFeaturesOverveiwItemsContianer = styled.div`
  max-width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4rem;
`;

export const StyledSwiperButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const StyledSwiperButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  width: 50px;
  height: 50px;
  background-color: transparent;
  border: 0.5px solid #000000;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 30%;
  }
`;

export const StyledFeaturesOverveiwItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 390px;
  gap: 1rem;

  & > div {
    max-width: 390px;
    max-height: 390px;

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  & > h6 {
    font-size: 24px;
    font-weight: 700;
    color: #000000;
    text-align: center;
  }
  & > p {
    font-size: ${({ matches }) => (matches ? "1.25rem" : ".90rem")};
    font-weight: ${({ matches }) => (matches ? "500" : "400")};
    line-height: ${({ matches }) => (matches ? "2rem" : "1.5rem")};
    text-align: center;
    color: #676767;
    max-width: 80%;
  }
`;
