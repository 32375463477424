import React from "react";
import "./index.css";

const SmallItemContent = ({ backgroundColor, path, text }) => {
  return (
    <div
      className="small-ftr-lst-1"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor,
        padding: "1rem",
        flexDirection: "column",
      }}
    >
      <div className="small-feature-animation-1"></div>
      <div className="small-feature-animation-2"></div>
      <div className="small-feature-animation-3"></div>
      <h6 style={{ fontSize: "24px", fontWeight: "600", textAlign: "center" }}>
        {text}
      </h6>
      <img
        className="small-feature-animation-img"
        src={path}
        alt="Company Logo"
        style={{ marginLeft: text === "Jobs" && "2rem" , width: text === "Career Discovery" ? "70%" : "90%" }}
      />
    </div>
  );
};

const SmallFeatureList = () => {
  const data = [
    {
      backgroundColor: "#d8e3fc",
      path: require("../../../Assets/Images/FeatureListImages/SmallScreen/smallImage3.png"),
      text: "Jobs",
    },
    {
      backgroundColor: "#b9e4a6bf",
      path: require("../../../Assets/Images/FeatureListImages/SmallScreen/smallImage1.png"),
      text: "Coaching",
    },
    {
      backgroundColor: "rgba(217, 188, 255, 0.75)",
      path: require("../../../Assets/Images/FeatureListImages/SmallScreen/smallImage2.png"),
      text: "Career Discovery",
    },
    {
      backgroundColor: "rgba(102, 145, 255, 0.6)",
      path: require("../../../Assets/Images/FeatureListImages/SmallScreen/smallImage4.png"),
      text: "Feed",
    },
  ];

  return (
    <div className="small-feature-container">
      {data.map((item) => (
        <SmallItemContent
          backgroundColor={item.backgroundColor}
          path={item.path}
          text={item.text}
        />
      ))}
    </div>
  );
};

export default SmallFeatureList;
