import React from "react";
import "./index.css";
import Header from "../../Header";
import Footer from "../../Footer";
import { useParams } from "react-router-dom";
import JobDetails from "./JobDetails/JobDetails";

const JobDetailsAndApplication = () => {
  const { id } = useParams();


  return (
    <div className="open-poition-main-container">
      <Header currentPath={`/open-positions/${id}`} isActive={true} />
      <div>
        <JobDetails />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default JobDetailsAndApplication;
