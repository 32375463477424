import React, { useEffect, useState } from "react";
import {
  StyledFormInputItemWrapper,
  StyledFormInputMultipleItemWrapper,
  StyledJoinMapoutConentWrapper,
  StyledJoinMapoutFormContainer,
  StyledJoinMapoutFormWrapper,
  StyledJoinMapoutWrapper,
  StyledErrorText,
  StyledSuccessText,
} from "./JoinMapout.styled";
import CommonBlackBgButton from "../../Common/Utils/Buttons/CommonBlackBgButton";
import { getAllCountryCodes } from "../../../Api/common";
import { saveEmployerQuery } from "../../../Api/employer";

const JoinMapout = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    countryCode: "+91",
    phone: "",
    jobTitle: "",
    companyName: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [allCountryCode, setAllCountryCodes] = useState([]);
  const [userCountryCode, setUserCountryCodes] = useState({});
  const [querySent, setquerySent] = useState({ status: false, msg: "" });

  useEffect(() => {
    setCountryCode();
  }, []);

  const setCountryCode = async () => {
    const { data } = await getAllCountryCodes();
    setAllCountryCodes(data?.data?.allCountryDetails);
    setUserCountryCodes(data?.data?.userCountryDetails);
  };
  useEffect(() => {
    setErrors({});
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.phone) newErrors.phone = "Phone is required";
    if (!formData.jobTitle) newErrors.jobTitle = "Job title is required";
    if (!formData.companyName)
      newErrors.companyName = "Company name is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsSubmitting(true);

    try {
      const { data } = await saveEmployerQuery(formData);
      console.log(data);
      setquerySent({ status: true, msg: data?.message });
    } catch (error) {
      console.error("There was an error!", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <StyledJoinMapoutWrapper>
      <StyledJoinMapoutConentWrapper>
        <h3>MapOut is not just another job board.</h3>
        <p>
          In today's competitive talent landscape, your talent strategy needs to
          be as active and strategic as your business. MapOut isn't just a
          platform for posting jobs and connecting with talent; it's a dynamic
          ecosystem where your brand earns the attention of top talent.
        </p>
      </StyledJoinMapoutConentWrapper>
      <StyledJoinMapoutFormWrapper>
        <StyledJoinMapoutFormContainer onSubmit={handleSubmit}>
          <h6>Talk to Us</h6>

          <StyledFormInputItemWrapper>
            <label htmlFor="name">
              Your Name*{" "}
              {errors.name && <StyledErrorText>{errors.name}</StyledErrorText>}
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </StyledFormInputItemWrapper>

          <StyledFormInputMultipleItemWrapper>
            <StyledFormInputItemWrapper>
              <label htmlFor="email">
                Your Email*{" "}
                {errors.email && (
                  <StyledErrorText>{errors.email}</StyledErrorText>
                )}
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </StyledFormInputItemWrapper>
            <StyledFormInputItemWrapper>
              <label htmlFor="phone">
                Your Mobile No.*{" "}
                {errors.phone && (
                  <StyledErrorText>{errors.phone}</StyledErrorText>
                )}
              </label>
              <div>
                <select
                  name="countryCode"
                  value={formData.countryCode}
                  onChange={handleChange}
                >
                  {userCountryCode ? (
                    <option
                      value={userCountryCode?.code}
                    >{`${userCountryCode?.flag} (${userCountryCode?.code}) ${userCountryCode?.name}`}</option>
                  ) : (
                    <option
                      value={userCountryCode?.code}
                    >{`Loading...`}</option>
                  )}
                  {allCountryCode.length > 0 &&
                    allCountryCode.map((item) => (
                      <option
                        value={item?.code}
                      >{`${item?.flag} (${item?.code})  ${item?.name}`}</option>
                    ))}
                </select>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
            </StyledFormInputItemWrapper>
          </StyledFormInputMultipleItemWrapper>

          <StyledFormInputItemWrapper>
            <label htmlFor="jobTitle">
              Your Job title*{" "}
              {errors.jobTitle && (
                <StyledErrorText>{errors.jobTitle}</StyledErrorText>
              )}
            </label>
            <input
              type="text"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleChange}
            />
          </StyledFormInputItemWrapper>
          <StyledFormInputItemWrapper>
            <label htmlFor="companyName">
              Company Name*{" "}
              {errors.companyName && (
                <StyledErrorText>{errors.companyName}</StyledErrorText>
              )}
            </label>
            <input
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
            />
          </StyledFormInputItemWrapper>
          <StyledFormInputItemWrapper>
            <label htmlFor="message">Type your message</label>
            <textarea
              name="message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
            />
          </StyledFormInputItemWrapper>
          {querySent.status ? (
            <StyledSuccessText>{querySent?.msg}</StyledSuccessText>
          ) : (
            <CommonBlackBgButton type="submit" isDisabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "Submit"}
            </CommonBlackBgButton>
          )}
        </StyledJoinMapoutFormContainer>
      </StyledJoinMapoutFormWrapper>
    </StyledJoinMapoutWrapper>
  );
};

export default JoinMapout;
