import React, { useState } from 'react';
import "./index.css";
import close from "../../../Assets/Images/closeIcon.png"
const SmallAboutTeamMembers = (props)=>{
    const {teamData} = props;
    const [selectedMember, setSelectedMember] = useState(false);

  return (
    <div className="about-mapout-team-members-container">
        {selectedMember && <div className="modal-members">
            <div className="modal-content-success-members">
              <div className="modal-heading-container-members">
                <span className="close" onClick={()=>{
                    setSelectedMember(false);
                }}>
                  <img src={close} alt="" style={{ position : "absolute" , width : "20px" , height : "20px" , right : "0px" , top : "0px" }}/>
                </span>
              </div>
              <img src={selectedMember?.imgUrlSelected} style={{alignSelf:'center'}} width={"80%"} alt="team member" />
              <p style={{color: '#000', textAlign: 'center', fontSize: '20px', fontWeight: '700'}}>{selectedMember?.name}</p>
              <p style={{color: '#000', textAlign: 'center', fontSize: '16px', fontWeight: '500'}}>{selectedMember?.location}</p>
              <p style={{color: '#000', textAlign: 'center', fontSize: '18px', fontWeight: '600'}}>{selectedMember?.designation}</p>
              <div style={{height:1, backgroundColor: '#000'}}/>
              <p style={{color: '#000', textAlign: 'center', fontSize: '16px', fontWeight: '500'}}>{selectedMember?.tagline}</p>
            </div>
          </div>}
      <p
        style={{
          margin: "10vh 5vw 5vh 5vw",
          color: "#000",
          textAlign: "center",
          fontSize: "24px",
          fontWeight: "600",
        }}
      >
        MEET THE TEAM THAT CREATES SUCCESS FOR YOU
      </p>
      <div style={{ display: "flex", flexWrap: "wrap", margin: "0 3vw" }}>
        {teamData &&
          teamData.map((item) => {
            return (
              <div
                onClick={()=>{
                    setSelectedMember(item);
                }}
                style={{
                  width: "25.3vw",
                  margin: "0 3vw 32px 3vw",
                  cursor: 'pointer'
                }}
              >
                <img src={item?.imgUrl} width={"100%"} alt="team member" />
              </div>
            );
          })}
      </div>
    </div>);
}

export default SmallAboutTeamMembers;