import React from "react";
import "./index.css";
import Text1 from "./Text1";

const SmallAboutCareers = () => {
  const textITems = [
    "accessible",
    "borderless",
    "transparent",
    "within reach",
    "inclusive",
    "personalised",
  ];

  return (
    <div className="about-career-container">
      <div className="about-career-top-div">
        We envision a future where all careers are more:{" "}
      </div>
      <div className="about-career-bottom-div">
        {textITems.map((text, index) => {
          return <Text1 text={text} key={index}  isSmall />;
        })}
      </div>
    </div>
  );
};

export default SmallAboutCareers;
