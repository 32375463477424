import styled from "styled-components";

export const StyledTalentPowerHouseContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: #fff;
  padding: 2rem 0;

  h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 58.8px;
    letter-spacing: -0.015em;
    text-align: center;
    color: #000;
  }

  @media screen and (max-width: 1200px) {
    h2 {
      max-width: 90%;
      font-size: 35px;
    }
  }

  @media screen and (max-width: 768px) {
    height: auto;

    h2 {
      max-width: 80%;
      font-size: 24px;
      font-weight: 600;
      line-height: 34px;
      text-align: center;
    }
  }
`;
export const StyledTalentPowerSlideContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 4rem;
  button {
    background-color: transparent;
    border-radius: 50%;
    padding: 12px;
    font-size: 0.5;
    border: 0.5px #4c4c4c solid;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
    button {
      display: none;
    }
  }
`;

export const StyledTalentPowerSliderItem = styled.div`
  width: 90%;
  height: 500px;
  background: #e1e8fa94;
  padding: 0.5rem;
  padding-right: 1rem;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  div {
    flex: 2;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  p {
    font-size: 36px;
    font-weight: 700;
    line-height: 52.92px;
    letter-spacing: -0.015em;
    text-align: left;
    color: #000;
    flex: 1;
  }

  @media screen and (max-width: 1200px) {
    width: 90%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
    justify-content: center;

    div {
      max-height: 230px;
    }
    p {
      font-size: 21px;
      font-weight: 700;
      line-height: 29.4px;
      text-align: center;
      max-width: 80%;
    }
  }
`;

export const StyledTalentPowerFeaturesWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 70%;
  padding: 2rem;
  gap: 2rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 95%;
  }
`;
export const StyledTalentPowerFeaturesItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-top: ${({ borderColor }) => `2px solid ${borderColor}`};
  color: #000;
  padding-top: 1rem;
  margin-top: 2rem;

  h6 {
    font-size: 26px;
    font-weight: 700;
    line-height: 39px;
    text-align: center;
  }

  p {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    color: #676767;
    max-width: 80%;
  }

  @media screen and (max-width: 1200px) {
    p {
      max-width: 100%;
    }
  }
`;
