import React, { useEffect, useState } from "react";
import {
  StyledApplicationCompletedPopup,
  StyledApplicationCompletedPopupWrapper,
  StyledApplicationFormContainer,
  StyledApplicationFormHeader,
  StyledApplicationFormItemWrapper,
  StyledApplicationFormMultipleItemWrapper,
  StyledApplicationFormWrapper,
  StyledApplyButton,
  StyledErrorText,
  StyledJobDeatilsItemsTitle,
  StyledJobDetailsBonus,
  StyledJobDetailsContainer,
  StyledJobDetailsHeaderItemContainer,
  StyledJobDetailsHeaderWrapper,
  StyledJobDetailsJobDetailsContentWrapper,
  StyledJobDetailsOrderdList,
  StyledJobDetailsUnOrderdList,
} from "./JobDetails.Styled";
import left from "../../../../Assets/Icons/chevron/left.png";
import { useNavigate, useParams } from "react-router-dom";
import UploadComponent from "../../../Common/UploadComponent/UploadComponent";
import { getAllCountryCodes } from "../../../../Api/common";
import { applyToJobWithId, getAllOpenPositions } from "../../../../Api/job";
import { SyncLoader } from "react-spinners";
import close from "../../../../Assets/Icons/close.png";

const JobDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [jobData, setJobData] = useState({});
  const [stage, setStage] = useState(1);
  const [loading, setLoading] = useState(false);

  const getJobData = async () => {
    setLoading(true);
    const { data } = await getAllOpenPositions(id);
    setJobData(data?.openPostions[0]);
    setLoading(false);
  };

  useEffect(() => {
    getJobData();
    // eslint-disable-next-line
  }, []);

  const handleApplyClicked = () => {
    setStage(2);
  };

  const ApplyButton = () => (
    <StyledApplyButton onClick={handleApplyClicked}>
      Apply for the position
    </StyledApplyButton>
  );

  const JobDetailsComponent = () =>
    !loading ? (
      <StyledJobDetailsContainer>
        <StyledJobDetailsHeaderWrapper>
          <div>
            <img
              src={left}
              alt=""
              style={{ cursor: "pointer"}}
              onClick={() => navigate("/open-positions")}

            />
            <StyledJobDetailsHeaderItemContainer>
              <h3>{jobData.title}</h3>
              <div>
                <p>{jobData.location}</p>
                <span>•</span>
                <p>{jobData.workMode}</p>
                <span>•</span>
                <p>{jobData.type}</p>
                <span>•</span>
                <p>{jobData.experience}</p>
              </div>
            </StyledJobDetailsHeaderItemContainer>
          </div>
          <ApplyButton />
        </StyledJobDetailsHeaderWrapper>

        <StyledJobDetailsJobDetailsContentWrapper>
          <div>
            <StyledJobDeatilsItemsTitle>
              Job Description
            </StyledJobDeatilsItemsTitle>
            <p
              dangerouslySetInnerHTML={{
                __html: jobData.jobDescription,
              }}
            ></p>
          </div>

          {jobData?.responsibilities &&
            jobData?.responsibilities.length > 0 && (
              <div>
                <StyledJobDeatilsItemsTitle>
                  Responsibilities
                </StyledJobDeatilsItemsTitle>
                <StyledJobDetailsOrderdList>
                  {jobData.responsibilities.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </StyledJobDetailsOrderdList>
              </div>
            )}

          <div>
            {jobData?.qualifications && jobData?.qualifications.length > 0 && (
              <>
                <StyledJobDeatilsItemsTitle>
                  Qualifications
                </StyledJobDeatilsItemsTitle>
                <StyledJobDetailsUnOrderdList>
                  {jobData?.qualifications?.map((item, index) => (
                    <li>{item}</li>
                  ))}
                </StyledJobDetailsUnOrderdList>
              </>
            )}

            {jobData?.bonusPoints && jobData?.bonusPoints.length > 0 && (
              <StyledJobDetailsBonus>
                <p>Bonus points for:</p>

                <div>
                  {jobData?.bonusPoints?.map((item, index) => (
                    <>
                      <p>{item}</p>
                    </>
                  ))}
                </div>
              </StyledJobDetailsBonus>
            )}


          </div>

          <ApplyButton />
        </StyledJobDetailsJobDetailsContentWrapper>

        <p>
          We encourage applications from all qualified candidates. MapOut is an
          equal opportunity employer and values diversity at our company. We do
          not discriminate on the basis of race, religion, color, national
          origin, gender, sexual orientation, age, marital status, veteran
          status, or disability status.
        </p>
      </StyledJobDetailsContainer>
    ) : (
      <>
        <StyledJobDetailsContainer style={{ height: "100vh" }}>
          <SyncLoader
            color={"#000"}
            loading={loading}
            size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </StyledJobDetailsContainer>
      </>
    );

  const ApplicationComponent = () => {
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      mobile: "",
      noticePeriod: "",
      ctc: "",
      reason: "",
      cv: null,
      countryCode: "",
    });
    const [errors, setErrors] = useState({});
    const [allCountryCode, setAllCountryCodes] = useState([]);
    const [userCountryCode, setUserCountryCodes] = useState({});
    const { id: appliedJobId } = useParams();
    const navigate = useNavigate();
    const [isLoading, setisLoading] = useState(false);
    const [applied, setapplied] = useState({
      status: false,
      code: null,
      msg: "",
    });
    // const [applied, setApplied] = useState({ status: false, msg: "" });

    useEffect(() => {
      if (applied.status) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }

      return () => {
        document.body.style.overflow = "auto";
      };
    }, [applied]);

    useEffect(() => {
      setCountryCode();
    }, []);
    const setCountryCode = async () => {
      const { data } = await getAllCountryCodes();
      setAllCountryCodes(data?.data?.allCountryDetails);
      setUserCountryCodes(data?.data?.userCountryDetails);
      setFormData((prevFormData) => ({
        ...prevFormData,
        countryCode: data?.data?.userCountryDetails?.code,
      }));
    };

    useEffect(() => {
      setErrors({});
    }, [formData]);

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (file) => {
      setFormData({ ...formData, cv: file });
    };

    const validateForm = () => {
      let newErrors = {};
      if (!formData.name) newErrors.name = "Please fill in this information.";
      if (!formData.email) newErrors.email = "Please fill in this information.";
      if (!formData.mobile)
        newErrors.mobile = "Please fill in this information.";
      if (!formData.noticePeriod)
        newErrors.noticePeriod = "Please fill in this information.";
      if (!formData.ctc) newErrors.ctc = "Please fill in this information.";
      if (!formData.reason)
        newErrors.reason = "Please fill in this information.";
      if (!formData.cv) newErrors.cv = "Please attach your CV.";
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
      setisLoading(true);
      console.log("clickeddddd :: : :  : ");
      e.preventDefault();
      console.log("Current form data:", formData);
      const isValid = validateForm();
      console.log("isValid", isValid);
      if (isValid) {
        console.log("Form submitted:", formData);
        const dataForApi = new FormData();
        dataForApi.append("name", formData.name);
        dataForApi.append("email", formData.email);
        dataForApi.append("country_code", formData.countryCode);
        dataForApi.append("mobile", formData.mobile);
        dataForApi.append("notice_period", formData.noticePeriod);
        dataForApi.append("current_ctc", formData.ctc);
        dataForApi.append("why_should_you_be_hired", formData.reason);
        dataForApi.append("cv", formData.cv);
        dataForApi.append("job_id", appliedJobId);
        const res = await applyToJobWithId(dataForApi);
        if (res.status === 200) {
          setapplied({ status: true, code: 200, msg: res?.data?.message });
        } else if (res.status === 400) {
          setapplied({ status: true, code: 400, msg: res?.data?.message });
        } else {
          setapplied({ status: true, code: 500, msg: res?.data?.message });
        }
        setisLoading(false);
      }
    };

    return (
      <StyledApplicationFormContainer>
        <StyledApplicationFormHeader>
          <img src={left} alt="" onClick={() => setStage(1)} />
          <span>
            Apply for <br /> <b>{jobData.title}</b>
          </span>
        </StyledApplicationFormHeader>
        <StyledApplicationFormWrapper onSubmit={handleSubmit}>
          <StyledApplicationFormItemWrapper>
            <label htmlFor="name">Your Name*</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
            {errors.name && <StyledErrorText>{errors.name}</StyledErrorText>}
          </StyledApplicationFormItemWrapper>
          <StyledApplicationFormMultipleItemWrapper>
            <StyledApplicationFormItemWrapper>
              <label htmlFor="email">Your Email*</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && (
                <StyledErrorText>{errors.email}</StyledErrorText>
              )}
            </StyledApplicationFormItemWrapper>

            <StyledApplicationFormItemWrapper>
              <label htmlFor="mobile">Your mobile no*</label>
              <div>
                <select
                  name="countryCode"
                  value={formData.countryCode}
                  onChange={handleInputChange}
                >
                  {userCountryCode ? (
                    <option
                      value={userCountryCode?.code}
                    >{`${userCountryCode?.flag} (${userCountryCode?.code}) ${userCountryCode?.name}`}</option>
                  ) : (
                    <option
                      value={userCountryCode?.code}
                    >{`Loading...`}</option>
                  )}
                  {allCountryCode.length > 0 &&
                    allCountryCode.map((item) => (
                      <option
                        value={item?.code}
                      >{`${item?.flag} (${item?.code})  ${item?.name}`}</option>
                    ))}
                </select>
                <input
                  type="text"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleInputChange}
                />
              </div>
              {errors.mobile && (
                <StyledErrorText>{errors.mobile}</StyledErrorText>
              )}
            </StyledApplicationFormItemWrapper>
          </StyledApplicationFormMultipleItemWrapper>
          <StyledApplicationFormItemWrapper>
            <label htmlFor="noticePeriod">Current notice period*</label>
            <input
              type="text"
              name="noticePeriod"
              value={formData.noticePeriod}
              onChange={handleInputChange}
            />
            {errors.noticePeriod && (
              <StyledErrorText>{errors.noticePeriod}</StyledErrorText>
            )}
          </StyledApplicationFormItemWrapper>
          <StyledApplicationFormItemWrapper>
            <label htmlFor="ctc">Current CTC*</label>
            <input
              type="text"
              name="ctc"
              value={formData.ctc}
              onChange={handleInputChange}
            />
            {errors.ctc && <StyledErrorText>{errors.ctc}</StyledErrorText>}
          </StyledApplicationFormItemWrapper>
          <StyledApplicationFormItemWrapper>
            <label htmlFor="reason">
              Why should you be hired for this role?*
            </label>
            <textarea
              name="reason"
              rows="8"
              value={formData.reason}
              onChange={handleInputChange}
            />
            {errors.reason && (
              <StyledErrorText>{errors.reason}</StyledErrorText>
            )}
          </StyledApplicationFormItemWrapper>
          <StyledApplicationFormItemWrapper>
            <label htmlFor="cv">Please attach your CV*</label>
            <UploadComponent onFileChange={handleFileChange} />
            {errors.cv && <StyledErrorText>{errors.cv}</StyledErrorText>}
          </StyledApplicationFormItemWrapper>
          {
            <button type="submit">
              {" "}
              {isLoading ? "Loading..." : "Submit Application"}{" "}
            </button>
          }
        </StyledApplicationFormWrapper>
        {applied.status && (
          <StyledApplicationCompletedPopupWrapper>
            <StyledApplicationCompletedPopup>
              <img
                src={close}
                alt=""
                onClick={() => navigate("/open-positions")}
              />
              {applied.code === 200 ? (
                <p>
                  Thank you for applying at MapOut! We will reach out to you
                  soon if your profile suits us.
                </p>
              ) : applied.code === 400 ? (
                <p>{applied.msg}</p>
              ) : (
                <p>{applied.msg}</p>
              )}
            </StyledApplicationCompletedPopup>
          </StyledApplicationCompletedPopupWrapper>
        )}
      </StyledApplicationFormContainer>
    );
  };

  return stage === 1 ? <JobDetailsComponent /> : <ApplicationComponent />;
};

export default JobDetails;
