export const theme = {
  //color
  color: {},
  //Devices
  device: {
    extraSmall: "420px",
    small: "618px",
    medium: "828px",
    large: "1255px",
  },
  //Containers
  container: {
    extraLarge: "1455px",
  },
};
