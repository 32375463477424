import React, { useState } from "react";
import "./index.css";

const BigAboutTeamMembers = (props) => {
  const { teamData } = props;
  const [hoveredImageUrl, setHoveredImageUrl] = useState(null);
  const cardWidth =
    teamData && teamData.length >= 4 ? "17.5vw" : `${100 / teamData.length}%`;

  return (
    <div className="about-mapout-team-members-container">
      <p
        style={{
          margin: "15vh 5vw 10vh 5vw",
          color: "#000",
          textAlign: "center",
          fontSize: "40px",
          fontWeight: "700",
        }}
      >
        MEET THE TEAM THAT CREATES SUCCESS FOR YOU
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          alignItems: "top",
          paddingTop: "1rem",
          paddingBottom: "6rem",
        }}
      >
        {teamData &&
          teamData.map((item, index) => (
            <div
              key={index}
              style={{
                width: cardWidth,
                margin: "0 4vw 32px 0",
              }}
            >
              <img
                src={item.imgUrlSelected}
                width={"0px"}
                height={"0px"}
                alt="team member"
              />
              <img
                src={
                  hoveredImageUrl === item.imgUrlSelected
                    ? item.imgUrlSelected
                    : item.imgUrl
                }
                width={"100%"}
                height={"100%"}
                style={{
                  maxHeight : "230px",
                  maxWidth : "230px"
                }}
                alt="team member"
                onMouseEnter={() => setHoveredImageUrl(item.imgUrlSelected)}
                onMouseLeave={() => setHoveredImageUrl(null)}
              />
              <p
                style={{
                  color: "#000",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "24px",
                  fontWeight: 700,
                }}
              >
                {item.name}
              </p>
              <p
                style={{
                  color: "#000",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "18px",
                  fontWeight: "500",
                  margin: "12px 0",
                  textTransform: "uppercase",
                }}
              >
                {item.location}
              </p>
              <p
                style={{
                  color: "#000",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  fontWeight: "600",
                  textTransform: "uppercase",
                }}
              >
                {item.designation}
              </p>
              <div
                style={{
                  width: "100%",
                  height: "1px",
                  backgroundColor: "#000",
                  margin: "12px 0",
                }}
              />
              <p
                style={{
                  color: "#000",
                  textAlign: "center",
                  display: "flex",
                  fontSize: "18px",
                  fontWeight: "500",
                  textTransform: "none",
                }}
              >
                {item.tagline}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default BigAboutTeamMembers;
