import React from "react";
import Header from "../Header";
import ContactUsMainPage from "./MainFormPage";
import Footer from "../Footer";
import CommonHelmet from "../Common/CommonHelmet/CommonHelmet";

const ContactUs = () => {
  return (
    <>
      <CommonHelmet
        title="MapOut Support Center: Contact Us for More Information About Career Planning | Career Coaching in Your Pocket"
        description="Get in-touch through our form or email for personalized support. MapOut's team is ready to help you with your career guidance inquiries."
      />
      <div className="contact-us-main-container">
        <Header />
        <div>
          <ContactUsMainPage />
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default ContactUs;
