import React, { useEffect, useState } from "react";
import {
  StyledInsightsContentContainer,
  StyledInsightsImgContainer,
  StyledInsightsWrapper,
} from "./Insights.styled";
import CommonBlackBgButton from "../../Common/Utils/Buttons/CommonBlackBgButton";
import InsightImage from "../../../Assets/Images/insightsImg.png";
import { useScroll } from "../../../Utils/scrollContext";
const Insights = () => {

  const  { scrollToJoinMapout } = useScroll()
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    const mediaQuery = window.matchMedia("(min-width: 768px)");
    mediaQuery.addEventListener("change", handler);
    return () => {
      mediaQuery.removeEventListener("change", handler);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return matches ? (
    <>
      <StyledInsightsWrapper>
        <StyledInsightsContentContainer>
          <h3>Discover insights that truly reveal</h3>
          <p>
            Forget chasing vanity metrics! At MapOut we ditch the fluff and help
            you discover what truly resonates with the kind of talent you
            desire. We get you actionable insights that help you refine your
            approach and build a true talent pipeline that’s attracted by your
            company's unique culture and values.
          </p>

          <CommonBlackBgButton onClick={scrollToJoinMapout} >Register</CommonBlackBgButton>
        </StyledInsightsContentContainer>
        <StyledInsightsImgContainer>
          <img src={InsightImage} alt="insights" />
        </StyledInsightsImgContainer>
      </StyledInsightsWrapper>
    </>
  ) : (
    <>
      <StyledInsightsWrapper>
        <h3>Discover insights that truly reveal</h3>
        <StyledInsightsImgContainer>
          <img src={InsightImage} alt="insights" />
        </StyledInsightsImgContainer>
        <StyledInsightsContentContainer>
          <p>
            Forget chasing vanity metrics! At MapOut we ditch the fluff and help
            you discover what truly resonates with the kind of talent you
            desire. We get you actionable insights that help you refine your
            approach and build a true talent pipeline that’s attracted by your
            company's unique culture and values.
          </p>

          <CommonBlackBgButton onClick={scrollToJoinMapout} >Register</CommonBlackBgButton>
        </StyledInsightsContentContainer>
      </StyledInsightsWrapper>
    </>
  );
};

export default Insights;
