import React from "react";
import "./index.css";
import Text1 from "./Text1";

const BigAboutCareers = () => {
  const textITems = [
    "accessible",
    "borderless",
    "transparent",
    "within reach",
    "inclusive",
    "personalised",
  ];

  return (
    <div
      // className="discover-container"
      style={{
        minHeight: "100vh",
        overflow: "auto",
        maxWidth: "100%",
        overflowX: "hidden",
        backgroundColor: "rgba(255, 255, 255, 1)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "64px",
        }}
      >
        <p
          style={{
            paddingTop: "100px",
            fontSize: "40px",
            fontWeight: "500",
            color: "#000",
          }}
        >
          We envision a future where all careers are more:
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "100px 0",
          }}
        >
          {textITems.map((text, index) => {
            return <Text1 text={text} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default BigAboutCareers;
