import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import logo from "../../Assets/Icons/mapout-logo.svg";
import logoName from "../../Assets/Icons/mapout-name.svg";
import mapoutBgBlackLogo from "../../Assets/Images/bgblackmapoutlogo.png"
import hamburger from "../../Assets/Icons/hamburger.png";
import close from "../../Assets/Icons/close.png";
import {
  StyledHeaderContainer,
  StyledHeaderSubContainer1,
  StyledHeaderSubContainer2,
  StyledHeaderLogo,
  StyledHeaderLogoName,
  StyledHeaderItem,
  StyledHeaderSubContainer3,
  StyledHamburgerMenu,
  StyledMenuWrapper,
  StyledMenuItemsContainer,
} from "./header.styled";

const Header = ({ forEmp = false, currentPath, isActive = false }) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeItem, setActiveItem] = useState("For Talent");
  const [path, setpath] = useState(currentPath);

  useEffect(() => {
    navigate(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem]);

  const menuItems = [
    { name: "For Talent", path: "/" },
    { name: "For Employers", path: "/employers" },
    { name: "About", path: "/about-us" },
    { name: "Open positions", path: "/open-positions" },
  ];

  useEffect(() => {
    setActiveItem(menuItems.find((item) => item.path === currentPath)?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItems]);

  const handleItemClick = (itemName, path) => {
    setActiveItem(itemName);
    setpath(path);
    if (menuOpen) setMenuOpen(false);
  };

  const renderMenuItems = () => (
    <>
      {menuItems.map((item, index) => (
        <StyledHeaderItem
          key={index}
          forEmp={forEmp}
          active={
            activeItem === item.name ||
            (item.name === "Open positions" && isActive)
          }
          onClick={() => handleItemClick(item.name, item.path)}
        >
          {item.name}
        </StyledHeaderItem>
      ))}
    </>
  );

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector("header");
      if (window.scrollY > window.innerHeight) {
        header.classList.add("scrolled");
      } else {
        header.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <StyledHeaderContainer>
      <StyledHeaderSubContainer1
        onClick={() => handleItemClick("For Talent", "/")}
      >
        <StyledHeaderLogo src={mapoutBgBlackLogo} alt="logo" />
        <StyledHeaderLogoName src={logoName} alt="logo name" />
      </StyledHeaderSubContainer1>
      <StyledHeaderSubContainer2>{renderMenuItems()}</StyledHeaderSubContainer2>
      <StyledHeaderSubContainer3>
        <StyledMenuWrapper onClick={() => setMenuOpen(!menuOpen)}>
          <StyledHamburgerMenu
            src={menuOpen ? close : hamburger}
            alt="menu icon"
          />
        </StyledMenuWrapper>
        {menuOpen && (
          <StyledMenuItemsContainer>
            {renderMenuItems()}
          </StyledMenuItemsContainer>
        )}
      </StyledHeaderSubContainer3>
    </StyledHeaderContainer>
  );
};

export default Header;
