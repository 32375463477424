import React from "react";
import "./index.css";
import nudgeImage from "../../../Assets/Images/nudgeImageWithbgSmall.png";
// import { redirectToAppStore } from "../../../Utils/platformCheck";

const SmallNudgeToDownload = () => {
  const handleSignUpClicked = () => { window.scrollTo({ top: 0, behavior: 'smooth', }); };
  return (
    <div className="nudge-to-download-container">
      <p className="nudge-top-text">Never feel short of career support again.</p>
        <img className="nudge-to-download-image" src={nudgeImage} alt="Company Logo" />
      <p className="nudge-bottom-text">
        Access MapOut for free and join your peers gaining career advantage with us! 
      </p>
      {/* <button onClick={redirectToAppStore} className="nudge-down-button">Download Now</button> */}
      <button onClick={handleSignUpClicked} className="nudge-down-button">Sign up for Free</button>
    </div>
  );
};

export default SmallNudgeToDownload;
