import React, { useEffect, useState } from "react";
import BigProfile from "./bigScreen";
import SmallProfile from "./smallScreen";

const Profile = () => {
  const [matches, setMatches] = useState(window.matchMedia("(min-width: 768px)").matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    const mediaQuery = window.matchMedia("(min-width: 768px)");
    mediaQuery.addEventListener('change', handler);
    return () => {
      mediaQuery.removeEventListener('change', handler);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {matches ? (<BigProfile/>) : (<SmallProfile/>)}
    </>
  );
};

export default Profile;
