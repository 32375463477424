import styled from "styled-components";

export const StyledHeaderContainer = styled.header`
  display: flex;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 4rem;
  width: 100%;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.6);
  transition: background-color 0.3s ease;

  &.scrolled {
    background-color: rgba(255, 255, 255, 1);
  }

  @media (max-width: 920px) {
    padding: 1.5rem 1rem;
  }

  @media (max-width: 768px) {
  background-color: rgba(255, 255, 255, 0);
    padding: 16px;
  }
`;

export const StyledHeaderSubContainer1 = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledMenuItemsContainer = styled.div`
  position: absolute;
  right: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  background-color: #fff;
  padding: 1rem;
  max-width: 225px;
  width: 100%;
  align-items: flex-start;
  border-radius: 12px;
`;

export const StyledHeaderSubContainer2 = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledHeaderSubContainer3 = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

export const StyledHeaderItem = styled.button`
  background: ${({ active }) => (active ? "#EEF3FF" : "transparent")};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 18px;
  padding: 12px 20px;
  border-width: ${(props) => (props.forEmp ? 0.5 : 0)};
  margin: 0 4px;

  &:hover {
    background-color: #fbfbfbba;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 30px;
    border-radius: 1.5rem;
    padding: 6px 15px;
    width: 150px;
    text-align: left;

    background: ${({ active }) =>
      active ? "rgba(216, 227, 252, 0.45)" : "transparent"};
  }
`;

export const StyledHeaderDownloadApp = styled.button`
  border: none;
  background-color: white;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 32px;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 12px;
    font-weight: 600;
    padding: 6px 12px;
    line-height: 30px;
    border-radius: 0.5rem;
  }
`;

export const StyledHeaderLogo = styled.img`
  height: 2rem;

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }

  @media (max-width: 475px) {
    width: 50px;
    height: 50px;
  }
`;

export const StyledHeaderLogoName = styled.img`
  height: 1rem;
  margin-left: 1rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledHamburgerMenu = styled.img`
  @media (min-width: 768px) {
    display: none;
  }
`;

export const StyledMenuWrapper = styled.div`
  width: 50px;
  height: 50px;
  background-color: #f5f5f5b4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #ffffffdc;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;
