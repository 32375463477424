import logo1 from "../Assets/trustedLogos/1.png";
import logo2 from "../Assets/trustedLogos/2.png";
import logo3 from "../Assets/trustedLogos/3.png";
import logo4 from "../Assets/trustedLogos/4.png";
import logo5 from "../Assets/trustedLogos/5.png";
import logo6 from "../Assets/trustedLogos/6.png";
import logo7 from "../Assets/trustedLogos/7.png";
import logo8 from "../Assets/trustedLogos/8.png";
import logo9 from "../Assets/trustedLogos/9.png";
import logo10 from "../Assets/trustedLogos/10.png";
import logo11 from "../Assets/trustedLogos/11.png";
import logo12 from "../Assets/trustedLogos/12.png";
import logo13 from "../Assets/trustedLogos/13.png";

export const trustedLogos = [
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  logo8,
  logo9,
  logo10,
  logo11,
  logo12,
  logo13,
];
