import React, { useState } from "react";
import {
  StyledCareerTasterMobileCard,
  StyledCareerTastersImageWrapper,
  StyledCareerTastersJoinButton,
  StyledCareerTastersMobileItemsWrapper,
  StyledCareerTastersTextWrapper,
  StyledCareerTastersWrapper,
} from "./CareerTasters.styled";
import gradient from "../../../Assets/Images/blueGradient.png";
import Lottie from "lottie-react";
import Animation from "../../../Assets/lottie/cardsfanout.json";

const CareerTasters = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleSignUpClicked = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const careerTasterItemsArray = [
    {
      name: "Human Resource Associate",
      bg: "#eeeeee",
    },
    {
      name: "Data Scientist",
      bg: "rgba(192, 236, 185, 0.45)",
    },
    {
      name: "Content Writer",
      bg: "rgba(255, 248, 212, 1)",
    },
    {
      name: "Block Chain Developer",
      bg: "rgba(208, 185, 236, 0.45)",
    },
    {
      name: "Crypto Trader",
      bg: "rgba(255, 248, 212, 1)",
    },
  ];

  return (
    <StyledCareerTastersWrapper>
      <StyledCareerTastersTextWrapper>
        <h4>
          Let’s <span>taste</span> different careers
        </h4>
        <p>
          Go beyond the coveted summer internships and get access to virtual
          internships with employers who are looking for curious and talented
          individuals like you. Get the insider scoop on a job before
          committing.
        </p>
        <h5>Download the APP, and start experiencing different careers!</h5>
      </StyledCareerTastersTextWrapper>
      <StyledCareerTastersImageWrapper
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Lottie
          animationData={Animation}
          loop
          play={isHovered}
          style={{ width: "100%", height: "100%" }}
        />
      </StyledCareerTastersImageWrapper>
      <StyledCareerTastersMobileItemsWrapper>
        <>
          {careerTasterItemsArray.map((item) => (
            <StyledCareerTasterMobileCard key={item.name} bg={item.bg}>
              <div>
                <h6>{item.name}</h6>
              </div>
              <span>{"Explore Career >"}</span>
            </StyledCareerTasterMobileCard>
          ))}
          <img src={gradient} alt="" />
        </>
      </StyledCareerTastersMobileItemsWrapper>
      <StyledCareerTastersJoinButton onClick={handleSignUpClicked}>
        Join Today
      </StyledCareerTastersJoinButton>
    </StyledCareerTastersWrapper>
  );
};

export default CareerTasters;
