import React from "react";
import { Link } from "react-router-dom";
import "./termsofuse.css";

const TermsOfUse = () => {
  return (
    <>
      <div className="termsOfUse">
        <div>
          <div className="backButton">
            {" "}
            <Link to="/">
              <button> ← Back To Home</button>
            </Link>
          </div>
          <div className="top-block">
            <p>
              <p className="heading">TERMS OF USE</p>
              <hr />
              <p className="content">
                PLEASE READ THE TERMS AND CONDITIONS THOROUGHLY AND CAREFULLY.
              </p>

              <div>
                <p className="bold">A. Acceptance of Terms</p>
                <p className="content">
                  The terms and conditions set forth below (“<b>Terms of Use</b>
                  ”) and the Privacy Policy constitute a legally-binding
                  agreement between Map Out Private Limited (“<b>MapOut</b>”) (“
                  <b>we</b>”, “<b>our</b>” or “<b>us</b>”), and any user of this
                  Platform including Service User (as defined hereunder) (“
                  <b>You</b>” or “<b>Your</b>”). These Terms of Use contain
                  provisions that define your limits, legal rights and
                  obligations with respect to your use of and participation in
                  (i) MapOut Platform, including the classified advertisements,
                  forums, various email functions and Internet links, and all
                  content and MapOut services available through the domain and
                  sub-domains of MapOut located at{" "}
                  <a
                    href={`https://www.mapout.com/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{`www.mapout.com`}</a>
                  (collectively referred to herein as the “Platform”), and (ii)
                  the online transactions with respect to mentorship between
                  those users of the Platform who are offering/ providing
                  services (each, a “<b>Service Professional</b>”) and those
                  users of the Platform who are obtaining/ using services (each,
                  a “<b>Service User</b>”) through the Platform (such services,
                  collectively, the “<b>Services</b>”, as defined hereinafter).
                  The Terms of Use described below incorporate the Privacy
                  Policy and apply to all users of the Platform, including users
                  who are also contributors of information, private and public
                  messages, advertisements, and other materials or Services on
                  the Platform.
                </p>
                <br />
                <p className="content">
                  {" "}
                  The Platform is currently owned and operated by Map Out
                  Private Limited.
                </p>
                <br />
                <p className="content">
                  You acknowledge that the Platform serves as a platform for
                  agreeing upon Services between Service Professionals and
                  Service Users, and, by using, visiting, registering for,
                  and/or otherwise participating in this Platform, including the
                  Services presented, promoted, and displayed on the Platform,
                  and by clicking on “I have read and agree to the terms of
                  use,” you hereby certify that: (1) you are a Service User or a
                  Service Professional (2) you have the authority to enter into
                  these Terms of Use, (3) you are authorized to make payment for
                  Services requested through the use of the Platform, and (4)
                  you agree to be bound by all terms and conditions of these
                  Terms of Use and any other documents incorporated by reference
                  herein. If you do not so agree to the foregoing, you should
                  not click to affirm your acceptance thereof, in which case you
                  are prohibited from accessing or using the Platform. If you do
                  not agree to any of the provisions set forth in the Terms of
                  Use, kindly discontinue viewing or participating in this
                  Platform immediately.
                </p>
                <br />
                <p className="content">
                  {" "}
                  All references to “You” or “Your”, as applicable, mean the
                  person that accesses, uses, and/or participates in the
                  Platform in any manner. If you use the Platformor open an
                  Account (as defined below) on behalf of a business, you
                  represent and warrant that you have the authority to bind that
                  business and your acceptance of the Terms of Use will be
                  deemed an acceptance by that business and “You” and “Your”
                  herein shall refer to that business.
                </p>
                <p className="content small-heading">
                  1. MODIFICATIONS TO TERMS OF USE AND/OR PRIVACY POLICY
                </p>
                MapOut reserves the right, in its sole discretion, to change,
                modify, or otherwise amend the Terms of Use, and any other
                documents incorporated by reference herein for complying with
                legal and regulatory framework and for other legitimate business
                purposes, at any time, and MapOut will post notice of the
                changes and the amended Terms of Use at the domain of{" "}
                <a
                  href={`https://mapout.com/termsOfUse`}
                  target="_blank"
                  rel="noopener noreferrer"
                >{`https://mapout.com/termsOfUse`}</a>
                . It is your responsibility to review the Terms of Use for any
                changes and you are encouraged to check the Terms of Use
                frequently. Your use of the Platform following any amendment of
                the Terms of Use will signify your assent to and acceptance of
                any revised Terms of Use. If you do not agree to abide by these
                or any future Terms of Use, please do not use or access the
                Platform.
                <p className="content small-heading">2. PRIVACY POLICY</p>
                <p className="content">
                  MapOut has established a Privacy Policy that explains to users
                  how their information is collected and used. The Privacy
                  Policy is referenced above and hereby incorporated into the
                  Terms of Use set forth herein. Your use of this Platform is
                  governed by the Privacy Policy.
                  <br />
                  The Privacy Policy is located at:{" "}
                  <a
                    href={`https://mapout.com/privacyPolicy`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{`https://mapout.com/privacyPolicy`}</a>
                </p>
              </div>

              <div className="terms-section">
                <p className="content bold">B. Membership and Accessibility</p>
                <p className="content small-heading">1. LICENSE TO ACCESS</p>
                <p>
                  MapOut hereby grants you a non-exclusive, revocable license to
                  use the Platform as set forth in the Terms of Use; provided,
                  however, that (i) you will not copy, distribute, or make
                  derivative works of the Platform in any medium without
                  MapOut’s prior written consent; (ii) you will not alter or
                  modify any part of the Platform other than as may be permitted
                  by the MapOut and are reasonably necessary to use the Platform
                  for its intended purposes; and (iii) you will otherwise act in
                  accordance with the terms and conditions of the Terms of Use
                  and in accordance with all applicable laws.
                </p>
                <p className="content">
                  <p className="content">
                    <p className="content small-heading">
                      2. MEMBERSHIP ELIGIBILITY CRITERIA
                    </p>
                    <p className="content">
                      Use of the Platform is available only to individuals who
                      are at least 18 years old and can form legally binding
                      contracts under applicable law. You represent, acknowledge
                      and agree that you are at least 18 years of age, and that:
                      (a) all registration information that you submit is
                      truthful and accurate, (b) you will maintain the accuracy
                      of such information, and (c) your use of the Platform and
                      Services offered through this Platform do not violate any
                      applicable law or regulation. Your Account (defined below)
                      may be terminated without warning if we at our discretion,
                      believe that you are under the age of 18 or that you are
                      not complying with any applicable laws, rules or
                      regulations. If you are below 18 and you wish to use the
                      Platform, you must get consent from your parents or
                      guardian before doing so.
                    </p>
                    <br />
                    <p className="content">
                      {" "}
                      You need not register with MapOut to simply visit and view
                      the Platform and services available there or participate
                      in free information available on the Platform, but to
                      access and participate in certain features of the
                      Platform, you will need to create a password-protected
                      account (“
                      <b>Account</b>”). These certain features include, but are
                      not limited to interaction with mentors, booking a
                      mentorship session etc. To create an account, you must
                      submit your name and email address through the account
                      registration page on the Platform and create a password.
                      You will also have the ability to provide additional
                      optional information, which is not required to register
                      for an account but may be helpful to MapOut in providing
                      you with a more customized experience when using the
                      Platform. You may also register for an Account using your
                      existing Facebook, Google, etc. account and log-in
                      credentials (your “<b>Third-Party Site Password</b>”).
                    </p>
                    <br />
                    <p className="content">
                      You are solely responsible for safeguarding your MapOut
                      password and, if applicable, your Third-Party Site
                      Password (collectively, “<b>Passwords</b>”) at all times
                      and shall keep your Passwords secure at all times. You
                      shall be solely responsible for all activity that occurs
                      on your Account and you shall notify MapOut immediately of
                      any breach of security or any unauthorized use of your
                      Account. Similarly, you shall never use another’s Account
                      without MapOut’s permission. You agree that you will not
                      misrepresent yourself or represent yourself as another
                      user of the Platform and/or the Services offered through
                      the Platform.{" "}
                    </p>
                    <br />
                    <p className="content">
                      You hereby expressly acknowledge and agree that you
                      yourself and not MapOut will be liable for your losses,
                      damages etc. (whether direct or indirect) caused by an
                      unauthorized use of your Account. Notwithstanding the
                      foregoing, you may be liable for the losses of MapOut or
                      others due to such unauthorized use.
                    </p>
                    <br />
                    <p className="content">
                      {" "}
                      You acknowledge and agree that you shall comply with the
                      following policies (the “<b>Account Policies</b>”):
                      <ul style={{ marginLeft: "2rem" }}>
                        <li>
                          You will not copy or distribute any part of the
                          Platform in any medium without MapOut’s prior written
                          authorization.
                        </li>
                        <li>
                          You will not alter or modify any part of the Platform
                          other than as may be reasonably necessary to use the
                          Platform for its intended purpose.
                        </li>
                        <li>
                          You will provide true, accurate, current and complete
                          information when creating your Account and you shall
                          maintain and update such information during the term
                          of this Agreement so that it will remain accurate,
                          true, current and complete.
                        </li>
                        <li>
                          You shall not in any manual or automated manner
                          collect Service Professionals or Service Users
                          information, including but not limited to, names,
                          addresses, phone numbers, or email addresses, copying
                          copyrighted text, or otherwise misuse or
                          misappropriate Platform information or content,
                          including but not limited to, use on a “mirrored”,
                          competitive, or third-party site.
                        </li>
                        <li>
                          You shall not use any artificial intelligence to use
                          the platform, copy the contents on the platform,
                          transmit requests or messages to the MapOut servers
                        </li>
                        <li>
                          You shall not take any action that (i) unreasonably
                          encumbers or, in MapOut’s sole discretion, may
                          unreasonably encumber the Platform’s infrastructure;
                          (ii) interferes or attempts to interfere with the
                          proper working of the Platform or any third-party
                          participation in the Platform; or (iii) bypasses
                          MapOut’s measures that are used to prevent or restrict
                          access to the Platform.
                        </li>
                        <li>
                          You agree not to collect or harvest any personally
                          identifiable data, including without limitation, names
                          or other Account information, from the Platform, nor
                          to use the communication systems provided by the
                          Platform for any commercial solicitation purposes.
                        </li>
                      </ul>
                      <p className="content small-heading">
                        3. ADDITIONAL POLICIES
                      </p>
                      <p className="content">
                        Your access to, use of, and participation in the
                        Platform is subject to the Terms of Use and all
                        applicable MapOut regulations, guidelines and additional
                        policies that MapOut may set forth from time to time,
                        including without limitation, a copyright policy and any
                        other restrictions or limitations that MapOut publishes
                        on the Platform (the“<b>Additional Policies</b>” ). You
                        hereby agree to comply with the Additional Policies and
                        your obligations thereunder at all times. You hereby
                        acknowledge and agree that if you fail to adhere to any
                        of the terms and conditions of this Agreement or
                        documents referenced herein, including the Account
                        Policies, membership eligibility criteria or Additional
                        Policies, MapOut, in its sole discretion, may terminate
                        your Account at any time without prior notice to you as
                        well as initiate appropriate legal proceedings, if
                        necessary. Any changes to the terms of use policies will
                        be notified to you at your next login and we assume that
                        you regularly read these Terms of Use and agree with the
                        changes made by us.
                      </p>
                    </p>
                  </p>
                </p>
              </div>

              <div className="terms-section">
                <p className="content bold">C. Feedback</p>
                <p>
                  As a participant in the Platform, you agree to use careful,
                  prudent, and good judgment when leaving Feedback for another
                  user. The following actions constitute inappropriate uses of
                  Feedback: (a) threatening to leave negative or impartial
                  Feedback; and (b) leaving Feedback in order to make the
                  Service Professional appear better than he or she actually is
                  or was.{" "}
                </p>
                <br />
                <p className="content">
                  {" "}
                  1.<i> Sanctions for Inappropriate Use of Feedback</i>. If you
                  violate any of the above-referenced rules in connection with
                  leaving Feedback, MapOut, in its sole discretion, may take any
                  of the following actions: (i) cancel your Feedback; (ii) limit
                  your Account privileges; and/or (iii) suspend your Account.
                </p>
                <br />
                <p className="content">
                  {" "}
                  2.<i> Reporting Inappropriate Use of Feedback. </i> You may
                  contact MapOut regarding any inappropriate use of Feedback
                  via-email at info@mapout.com.
                </p>
                <br /> The foregoing lists of prohibitions provide examples and
                are not complete or exclusive. MapOut reserves the right to (a)
                terminate your access to your Account, your ability to post to
                this Platform and (b) refuse, delete or remove, move or edit the
                content, in whole or in part, of any Postings; with or without
                cause and with or without notice, for any reason or no reason,
                or for any action that MapOut determines is inappropriate or
                disruptive to this Platform or to any other user of this
                Platform and/or Services. MapOut reserves the right to restrict
                the number of e-mails or other messages that you are allowed to
                send to other users to a number that MapOut deems appropriate in
                MapOut’s sole discretion. MapOut may report to law enforcement
                authorities any actions that may be illegal, and any reports it
                receives of such conduct. When legally required or at MapOut’s
                discretion, MapOut will cooperate with law enforcement agencies
                in any investigation of alleged illegal activity on this
                Platform or on the Internet. MapOut does not and cannot review
                every Posting posted to the Platform.
              </div>

              <div className="terms-section">
                <p className="content bold">D. Rules for Service Users</p>
                <p className="content small-heading">
                  1. SERVICE USERS SHALL NOT TAKE ANY OF THE FOLLOWING ACTIONS:
                </p>
                (a) commit to using a Service without paying (Except for free
                offers done as part of promotional campaigns run by Service
                Professionals and/or MapOut); (b) sign up for, negotiate a price
                for, use, or otherwise solicit a Service with no intention of
                following through with your use of or payment for the Service;
                (c) agree to purchase a Service when you do not meet the Service
                Professional’s or Platform’s terms as outlined in the Posting,
                or agree to purchase a Service with the intention of disrupting
                a Posting; or (d) misuse any options made available now or in
                the future by MapOut in connection with the use or purchase of
                any Service.
                <p className="content small-heading">
                  2. SANCTIONS FOR VIOLATING ANY OF THE RULES FOR SERVICE USERS
                </p>
                If a Service User violates any of the above-referenced rules in
                connection with the Posting, MapOut, in its sole discretion, may
                take any of the following actions: (a) limit the Service User’s
                Account privileges; and/or (b) suspend the Service User’s
                Account.
              </div>

              <div className="terms-section">
                <p className="content bold">E. Limitation of our Liability</p>
                <p>
                  You hereby release MapOut, its officers, directors, agents,
                  and employees from all claims, demands, and damages (actual
                  and consequential) of any kind and nature, known and unknown,
                  suspected and unsuspected, disclosed and undisclosed, arising
                  out of, or in any way, connected with any disputes arising
                  between you and any other party, or between you and other
                  Platformusers.
                </p>
                <br />
                <p>
                  None of the Services, including but not limited to mentoring
                  and mentorship sessions, listed on the Platformare provided by
                  us. We act as an intermediary between the Service Users and
                  the Service Professionals. All the service-related information
                  transmitted and posted on the Platformare by the Service
                  Professionals or upon their instructions.
                </p>
                <br />
                <p></p>
                We shall in no way be liable for the information related to the
                services posted on the Platform(all of the foregoing content is
                sometimes collectively referred to herein as “
                <b>Submitted Content</b>” and the posting of Submitted Content
                is sometimes referred to as a “<b>Posting</b>” or as “
                <b>Postings</b>”) that:
                <ul style={{ marginLeft: "2rem" }}>
                  <li>provides or create links to external sites;</li>
                  <li>
                    is intended to harm or exploit any individual under the age
                    of 18 in any way;
                  </li>
                  <li>
                    invades anyone’s privacy by attempting to harvest, collect,
                    store, or publish private or personally identifiable
                    information, such as names, email addresses, phone numbers,
                    passwords, account information, credit card numbers, home
                    addresses, or other contact information without their
                    knowledge and willing consent;
                  </li>
                  <li>
                    contains falsehoods or misrepresentations that could damage
                    us or any third party;
                  </li>
                  <li>
                    is pornographic, harassing, hateful, illegal, obscene,
                    defamatory, slanderous, threatening, discriminatory,
                    racially, culturally or ethnically offensive; incites,
                    advocates, or expresses pornography, obscenity, vulgarity,
                    profanity, hatred, bigotry, racism, or gratuitous violence;
                    encourages conduct that would be considered a criminal
                    offense, give rise to civil liability or violate any law;
                    promotes racism, hatred or physical harm of any kind against
                    any group or individual; contains nudity, violence or
                    inappropriate subject matter; or is otherwise inappropriate;
                  </li>
                  <li>
                    is copyrighted or otherwise subject to third-party
                    proprietary rights, including privacy and publicity rights
                    and contains or promotes an illegal or unauthorized copy of
                    another person’s copyrighted work, pirated music or links to
                    pirated music files, or lyrics, guitar tabs or sheet music,
                    works of art, teaching tools, or any other item the copy,
                    display, use, performance, or distribution of which
                    infringes on another’s copyright, intellectual property
                    right, or any other proprietary right;
                  </li>
                  <li>
                    is intended to threaten, stalk, defame, defraud, degrade,
                    victimize, or intimidate an individual or group of
                    individuals for any reason on the basis of age, gender,
                    disability, ethnicity, sexual orientation, race, or
                    religion; or to incite or encourage anyone else to do so;
                  </li>
                  <li>
                    advertises or solicits a business not related to or
                    appropriate for the Platform(as determined by us in our sole
                    discretion);
                  </li>
                  <li>
                    {" "}
                    contains or could be considered “junk”, “spam”, “pyramid
                    schemes”, “affiliate marketing”, or unsolicited commercial
                    advertisement;
                  </li>
                  <li>
                    contains advertising for ponzi schemes, discount cards,
                    credit counselling, online surveys or online contests;
                  </li>
                  <li>
                    {" "}
                    distributes or contains viruses or any other technologies
                    that may harm us, or the interests or property of our users;
                  </li>
                  <li>
                    contains links to commercial services or Platform; is
                    irrelevant content;
                  </li>
                  <li>
                    contains identical content to other open Postings you have
                    already posted; or
                  </li>
                  <li>
                    contains anything which is in violation of applicable laws
                    of India.
                  </li>
                </ul>
                <br />
                <p>
                  We shall in no way be responsible or liable, directly or
                  indirectly, for any damage or loss caused or alleged to be
                  caused by or in connection with the use of any posted service
                  on the Platform. The Service Professional acts as an
                  independent contractor and any act done by the Service
                  Professional makes them solely liable. We do not guarantee or
                  take responsibility for your satisfaction with the provided
                  Service. We shall in no way be liable if you do not achieve
                  the desired goal as a result of the provided Services.
                </p>
                <br />
                <p>
                  You assume all responsibility and risk with respect to your
                  use of the Platform. You understand and agree that, to the
                  fullest extent permitted by law, we disclaim all warranties,
                  representations and endorsements, express or implied, with
                  regard to the site, including, without limitation, implied
                  warranties of title, merchantability, non-infringement and
                  fitness for a particular purpose. We have no duty to update or
                  modify the Platform and we are not liable for our failure to
                  do so. In no event, under no legal or equitable theory
                  (whether tort, contract, strict liability or otherwise), shall
                  we or any of our respective employees, directors, officers,
                  agents or affiliates, be liable hereunder or otherwise for any
                  loss or damage of any kind, direct or indirect, in connection
                  with or arising from the Platform, the use of the Platform or
                  our agreement with you concerning the Platform, including, but
                  not limited to, compensatory, direct, consequential,
                  incidental, indirect, special or punitive damages, lost
                  anticipated profits, loss of goodwill, loss of data, business
                  interruption, accuracy of results, or computer failure or
                  malfunction, even if we have been advised of or should have
                  known of the possibility of such damages. If we are held
                  liable to you in a court of competent jurisdiction for any
                  reason, in no event will we be liable for any damages in
                  excess of USD 100. If any limitation on remedies, damages or
                  liability is prohibited or restricted by law, we shall remain
                  entitled to the maximum disclaimers and limitations available
                  under this agreement, at law and/or in equity.
                </p>
                <br />
              </div>

              <div className="terms-section">
                <p className="content bold">F. Services Offered</p>
                <p className="content">
                  MapOut is the go-to destination for the next-gen students to
                  receive first-hand guidance and insights about college and
                  work life. Our services are more detailed on the Platform
                  under Our Services section. You are advised to check the
                  Platform for more details of these services and their charges.
                </p>
              </div>

              <div className="terms-section">
                <p className="content bold">G. Force Majeure</p>
                <p className="content">
                  We shall not be liable for delayed or interruption in service
                  due to any force majeure event, arising out of any enforceable
                  circumstances and beyond our reach, including but not limited
                  to fire, flood, earthquakes, strikes, unavailability of
                  necessary utilities, black-out, acts of God, acts of declared
                  or undeclared war, acts of regulatory agencies, or natural
                  disaster.
                </p>
              </div>

              <div className="terms-section">
                <p className="content bold">
                  H. Copyright Infringement Take Down Procedure
                </p>
                <p className="content">
                  MapOut has high regard for intellectual property and expects
                  the same level of standard to be employed by its users. MapOut
                  may, in appropriate circumstances and at its discretion,
                  terminate the Account or prohibit access to the Platform of
                  users who infringe upon the intellectual property rights of
                  others.
                </p>
                <br />
                <p>
                  If you believe that your work has been copied and posted on
                  the Platform in a way that constitutes copyright infringement
                  and/or trademark infringement, please send the following
                  information to us at support@mapout.com
                </p>
                <br />
                <ol
                  style={{
                    listStyleType: "upper-roman",
                    listStylePosition: "inside",
                  }}
                >
                  <li>
                    identification of the material that is claimed to be
                    infringing or to be the subject of infringing activity and
                    that is to be removed or access to which is to be disabled
                    at the Platform, and information reasonably sufficient to
                    permit MapOut to locate the material.;
                  </li>
                  <br />
                  <li>
                    a written statement that you have a good faith belief that
                    the disputed use is not authorized by the copyright and/or
                    trademark owner, its agent, or the law;{" "}
                  </li>
                  <br />
                  <li>
                    information reasonably sufficient to permit MapOut to
                    contact you as the complaining party, such as an address,
                    telephone number, and, if available, an electronic mail
                    address at which you may be contacted;
                  </li>
                  <br />
                  <li>
                    an electronic or physical signature of the person authorized
                    to act on behalf of the owner of an exclusive interest that
                    is allegedly infringed; and
                  </li>
                  <br />
                  <li>
                    {" "}
                    a statement by you, made under penalty of perjury, that the
                    information in your report is accurate and that you are the
                    owner of the exclusive right or authorized to act on the
                    behalf of the owner of the exclusive right. A statement by
                    you comprised of the foregoing points is referred to herein
                    as the “Notice”.
                  </li>
                </ol>

                <br />
                <i>
                  Only the intellectual property rights owner is permitted to
                  report potentially infringing items through MapOut's reporting
                  system set forth above. If you are not the intellectual
                  property rights owner, you should contact the intellectual
                  property rights owner and they can choose whether to use the
                  procedures set forth in these Terms of Use.
                </i>
              </div>

              <div className="terms-section">
                <p className="content bold">
                  I. Modifications to or Termination of Platform
                </p>
                <p className="content small-heading">
                  1. MODIFICATION OR CESSATION OF PLATFORM
                </p>
                <p className="content">
                  MapOut reserves the right at any time and from time to time to
                  modify or discontinue, temporarily or permanently, the
                  Platform (or any part thereof) with or without notice and in
                  its sole discretion. You agree that MapOut shall not be liable
                  to you or to any third party for any modification, suspension
                  or discontinuance of MapOut services.
                </p>
                <p className="content small-heading">
                  2. TERMINATION BY MapOut
                </p>
                <p className="content">
                  You hereby acknowledge and agree that MapOut, in its sole and
                  absolute discretion, has the right (but not the obligation) to
                  delete, terminate, or deactivate your Account, block your
                  email or IP address, cancel the Platform or otherwise
                  terminate your access to or participation in the use of the
                  Platform (or any part thereof), or remove and discard any
                  Submitted Content on the Platform (“Termination of Service”),
                  immediately and without notice, for any reason, including
                  without limitation, Account inactivity or if MapOut believes
                  or has reason to believe that you have violated any provision
                  of the Terms of Use.
                </p>
                <p className="content small-heading">
                  3. EFFECT OF TERMINATION
                </p>
                <p>
                  Upon termination of your Account, your right to participate in
                  the Platform, including, but not limited to, your right to
                  offer or purchase Services and your right to receive any fees
                  or compensation, including, without limitation, referral
                  discounts, incentive bonuses, or other special offer rewards,
                  shall automatically terminate. You acknowledge and agree that
                  your right to receive any fees or compensation hereunder is
                  conditional upon your proper use of the Platform, your
                  adherence to the Terms of Use, the continuous activation of
                  your Account, and your permitted participation in the
                  Platform. In the event of Termination of Service, your Account
                  will be disabled and you may not be granted access to your
                  Account or any files or other data contained in your Account.
                  Notwithstanding the foregoing, residual data may remain in the
                  MapOut system.
                </p>
                <br />
                <p>
                  Upon Termination of Service, the following shall occur: all
                  licenses granted to you hereunder will immediately terminate;
                  and you shall promptly destroy all copies of MapOut Data (as
                  defined below), Marks (as defined below) and other content in
                  your possession or control. You further acknowledge and agree
                  that MapOut shall not be liable to you or any third party for
                  any termination of your access to the Platform. Upon
                  Termination of Service, MapOut retains the right to use any
                  data collected from your use of the Platform for internal
                  analysis and archival purposes, and all related licenses you
                  have granted MapOut hereunder shall remain in effect for the
                  foregoing purpose.
                </p>
                <br />
                <p>
                  You agree to indemnify and hold MapOut, and its officers,
                  managers, members, affiliates, successor, assigns, directors,
                  agents, service professionals, suppliers, and employees
                  harmless from any claim or demand, including reasonable
                  attorneys' fees and court costs, made by any third party due
                  to or arising out of the Termination of Service.
                </p>
              </div>

              <div className="terms-section">
                <p className="content bold">J. Intellectual Property Rights</p>
                <p className="content small-heading">
                  1. MapOut OWNS OR HOLDS THE LICENSES TO ALL DATA AND MARKS ON
                  THE PLATFORM
                </p>
                <br />
                <p>
                  The content on the Platform (exclusive of all Submitted
                  Content by Service Professionals), including without
                  limitation, the text, software, scripts, graphics, photos,
                  sounds, music, videos, interactive features and the like (“
                  <b>Data</b>”) and the trademarks, service marks and logos
                  contained therein (“
                  <b>Marks</b>”), are owned by MapOut. Other trademarks, names
                  and logos on this Platform are the property of their
                  respective owners.
                </p>

                <br />
                <p>
                  Data on the Platform is provided to you as is for your
                  information and personal use only and may not be used, copied,
                  reproduced, distributed, transmitted, broadcast, displayed,
                  sold, licensed, or otherwise exploited for any other purposes
                  whatsoever without the prior written consent of the respective
                  owners. MapOut reserves all rights not expressly granted in
                  and to the Platform and the Data. You agree not to use, copy,
                  or distribute, any of the Data other than as expressly
                  permitted herein, including any use, copying, or distribution
                  of Submitted Content obtained through the Platform for any
                  commercial purposes. If you download or print a copy of the
                  Data for personal use, you must retain all copyright and other
                  proprietary notices contained thereon. You agree not to
                  circumvent, disable or otherwise interfere with security
                  features of the Platform or features that prevent or restrict
                  use or copying of any Data or enforce limitations on use of
                  the Platform or the Data therein.
                </p>

                <p className="content small-heading">
                  2. MapOut’S LICENSE TO YOU FOR THE USE OF DATA AND MARKS
                </p>
                <p>
                  The Platform contains MapOut’s Data and Marks, which are, or
                  may become, protected by copyright, trademark, patent, trade
                  secret and other laws, and MapOut owns and retains all rights
                  in the MapOut Data and Marks. Subject to these Terms of Use,
                  MapOut hereby grants you a limited, revocable, non
                  transferable, non sublicensable license to reproduce and
                  display the MapOut Data (excluding any software source code)
                  solely for your personal use in connection with accessing and
                  participating in the Platform.
                </p>

                <br />
                <p>
                  The Platform may also contain Data of other users or
                  licensors, which you shall not copy, modify, translate,
                  publish, broadcast, transmit, distribute, perform, display, or
                  sell.
                </p>
              </div>

              <div className="terms-section">
                <p className="content bold">K. Governing Law</p>
                You hereby agree that the laws of Singapore shall govern these
                Terms and that the courts of Singapore shall have non-exclusive
                jurisdiction over any dispute arising out of or in connection
                with these Terms.
              </div>

              <div className="terms-section">
                <p className="content bold">L. Notice</p>
                You agree that MapOut may provide you with notices, including
                those regarding changes to the Terms of Use, by email, regular
                mail, messages, or postings on the Platform.
              </div>

              <div className="terms-section">
                <p className="content bold">M. General Information</p>
                <p className="content small-heading">1. ENTIRE TERMS OF USE</p>
                <p>
                  The Terms of Use, together with the Privacy Policy and any
                  other legal notices or Additional Policies published by MapOut
                  on the Platform, shall constitute the entire agreement between
                  you and MapOut concerning the Platform. If any provision of
                  the Terms of Use is deemed invalid by a court of competent
                  jurisdiction, the invalidity of such provision shall not
                  affect the validity of the remaining provisions of the Terms
                  of Use, which shall remain in full force and effect.
                </p>
                <br />
                <p>
                  A waiver by MapOut of any default or defaults by you in the
                  performance of any of the provisions of this Terms of Use
                  shall be effective only when executed in writing by duly
                  authorized representatives of MapOut.
                </p>
                <p className="content small-heading">
                  2. STATUTE OF LIMITATIONS
                </p>
                You agree that any cause of action arising out of or related to
                the Platform must commence within one (1) year after the cause
                of action accrues. Otherwise, such cause of action is
                permanently barred.
                <p className="content small-heading">3. SECTION HEADINGS</p>
                The section headings in the Terms of Use are for convenience
                only and have no legal or contractual effect.
              </div>

              <p
                style={{ margin: "5rem 0", textAlign: "center" }}
                className="small-heading"
              >
                These Terms of Use were most recently updated in November, 2023.
              </p>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfUse;
