import styled from "styled-components";

export const StyledCandidatesContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 4rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 4rem 1.5rem;

    & > h5 {
      font-size: 28px;
      font-weight: 600;
      line-height: 34px;
      text-align: center;
      color: #000;
    }
  }
`;

export const StyledCandidatesContentContainer = styled.div`
  color: #000;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: start;
  justify-content: center;
  h5 {
    font-size: 38px;
    font-weight: 700;
    line-height: 50px;
    text-align: left;
    max-width: 70%;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    max-width: 70%;
  }


  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    gap: 1rem;

    p {
      text-align: center;
      max-width: 95%;

      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }
`;

export const StyledCandidatesContentImg = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2rem;
  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    padding: 2rem 0rem;
  }
`;
