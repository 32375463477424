import React from "react";
import "./index.css";
// import { redirectToAppStore } from "../../../Utils/platformCheck";
import Lottie from "lottie-react";
import Animation from "../../../Assets/lottie/portfolio.json";

const SmallProfile = () => {
  const handleSignUpClicked = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="small-profile-container">
      <div className="profile-image-container">
        <div className="info-title">
          <p>Let's make your first impression</p>
          <p>your best impression.</p>
        </div>
        <Lottie className="profile-image" animationData={Animation} loop />
      </div>
      <div className="profile-info-container">
        <div className="profile-info-content">
          <p>
            Imagine having a platform that not only showcases your
            qualifications but also captures the essence of who you are as a
            professional. That's the power of MapOut.
          </p>
          <br />
          <p>
            In today's competitive job market, having a standout profile isn't
            just advantageous; it's essential.
          </p>
        </div>
        {/* <button onClick={redirectToAppStore} className="signup-button">Sign Up for Free</button> */}
        <button onClick={handleSignUpClicked} className="signup-button">
          Sign Up for Free
        </button>
      </div>
    </div>
  );
};

export default SmallProfile;
