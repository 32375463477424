import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./Components/Home/index.js";
import Privacy from "./Components/privacyPolicy/PrivacyPolicy.jsx";
import Terms from "./Components/termsOfUse/TermsOfUse.jsx";
import ForEmployer from "./Components/ForEmployers/index.js";
import OpenPositions from "./Components/OpenPositions/index.js";
import AboutUs from "./Components/AboutUs/index.js";
import ContactUs from "./Components/ContactUs/index.js";
import JobDetailsAndApplication from "./Components/OpenPositions/JobDetailsAndApplication/index.js";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacyPolicy" element={<Privacy />} />
      <Route path="/termsOfUse" element={<Terms />} />
      <Route path="/employers" element={<ForEmployer />} />
      <Route path="/open-positions" element={<OpenPositions/>} />
      <Route path="/open-positions/:id" element={<JobDetailsAndApplication />} />
      <Route path="/about-us" element={<AboutUs/>} />
      <Route path="/contact-us" element={<ContactUs/>} />
    </Routes>
  );
}

export default App;
