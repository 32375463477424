import React from "react";
import AboutMapout from "./AboutMapout";
import Header from "../Header";
import Footer from "../Footer";
import JoinUs from "./JoinUs";
import AboutCareers from "./AboutCareers";
// import AboutJobs from "./AboutJobs";
import AboutUsMission from "./AboutUsMission";
import AboutTeamMembers from "./AboutTeamMembers";
import CommonHelmet from "../Common/CommonHelmet/CommonHelmet";

const AboutUs = () => {
  return (
    <>
      <CommonHelmet
        title="MapOut About Us: Dedicated to Helping You Create a Roadmap for Building Your Career | AI Career Coaching"
        description="Our mission is to equip every individual with the confidence and roadmap for a fulfilling career, not just a job. Discover how our AI Career Planner app can help you on your career journey."
      />
      <div className="about-us-main-container">
        <Header currentPath="/about-us" />
        <div>
          <AboutMapout />
        </div>
        {/* <div>
        <AboutJobs/>
      </div> */}
        <div>
          <AboutCareers />
        </div>
        <div>
          <AboutUsMission />
        </div>
        <div>
          <AboutTeamMembers />
        </div>
        <div>
          <JoinUs />
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default AboutUs;
