import styled from "styled-components";

export const StyledDiscoverContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  flex-direction: column;
  h2 {
    font-size: 75px;
    font-weight: 600;
    color: #000;
    text-align: center;
    max-width: 70%;
  }

  h6 {
    font-size: 30px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    max-width: 70%;
    color: #000;
    margin-top: 1rem;
  }

  button {
    background-color: #fff;
    border: none;
    color: #000;
    padding: 15px 60px;
    border-radius: 34px;
    cursor: pointer;
    font-size: 14px;
    line-height: 30px;
    font-weight: 500;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 4rem;

    img {
      width: 30px;
    }
  }
`;

export const StyledSmallDiscoverContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  flex-direction: column;

  h2 {
    font-size: 45px;
    font-weight: 600;
    color: #000;
    text-align: center;
    max-width: 90%;
    margin-top: 3rem;
  }

  h6 {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    max-width: 70%;
    color: #000;
    margin-top: 1rem;
  }

  button {
    background-color: #fff;
    border: none;
    color: #000;
    padding: 12px 30px;
    border-radius: 34px;
    cursor: pointer;
    font-size: 14px;
    line-height: 30px;
    font-weight: 500;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 2rem;

    img {
      width: 20px;
    }
  }
`;
