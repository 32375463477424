import React from "react";
import "./index.css";

const BigAboutUsMission = () => {
  return (
    <div className="about-mapout-mission-container">
      <>
        <div
          style={{
            background:
              "linear-gradient(167.45deg, rgba(185, 228, 166, 0.375) 2.97%, rgba(194, 236, 205, 0.375) 54.64%, rgba(202, 243, 242, 0.275) 82.05%)",
            width: "30vw",
            height: "30vw",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            borderRadius: "50%",
          }}
        >
          <p style={{ color: "#000", fontSize: "35px", fontWeight: "700" }}>
            OUR MISSION
          </p>
          <p
            style={{
              margin: "0 2.5vw",
              color: "#000",
              fontSize: "20px",
              fontWeight: "400",
              textAlign: "center",
              lineHeight: "35px",
            }}
          >
            Our mission is to equip every individual with the confidence and{" "}
            <span style={{ fontWeight: "700" }}>
              roadmap for a fulfilling career, not just a job.
            </span>
          </p>
        </div>
        <div
          style={{
            background:
              "linear-gradient(347.45deg, rgba(185, 228, 166, 0.375) 3.27%, rgba(194, 236, 205, 0.375) 54.95%, rgba(202, 243, 242, 0.275) 82.36%)",

            width: "30vw",
            height: "30vw",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            borderRadius: "50%",
          }}
        >
          <p style={{ color: "#000", fontSize: "35px", fontWeight: "700" }}>
            OUR VISION
          </p>
          <p
            style={{
              margin: "0 2.5vw",
              color: "#000",
              fontSize: "20px",
              fontWeight: "400",
              textAlign: "center",
              lineHeight: "35px",
            }}
          >
            Our vision is to <b> empower everyonewith the tools and support </b>{" "}
            to achieve a <b> fulfilling and purposeful career, </b> unlocking
            their full potential and navigating their career paths with
            confidence and clarity.
          </p>
        </div>
        {/* <div
          style={{
            backgroundColor: "rgba(255,255,255,0.45)",
            width: "45vw",
            height: "45vw",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            marginLeft: "-3vw",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
            borderRadius: "50%",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          <p style={{ color: "#000", fontSize: "32px", fontWeight: "700" }}>
            OUR VISION
          </p>
          <p
            style={{
              margin: "0 3.2vw",
              color: "#000",
              fontSize: "24px",
              fontWeight: "300",
              textAlign: "center",
              lineHeight: "42px",
            }}
          >
            Our vision is to <b> empower everyonewith the tools and support </b>{" "}
            to achieve a <b> fulfilling and purposeful career, </b> unlocking
            their full potential and navigating their career paths with
            confidence and clarity.
          </p>
        </div> */}
      </>
    </div>
  );
};

export default BigAboutUsMission;
