import React from "react";
import styled from "styled-components";

const CommonBlackBgButton = ({ children, ...props }) => {
  return <Button {...props}> {children} </Button>;
};

const Button = styled.button`
  height: 38px;
  gap: 10px;
  border-radius: 20px;
  outline: none;
  border: none;
  color: #fff;
  padding: 1rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ isDisabled }) => (isDisabled ? "#7F8A8E" : "#000")};


  @media screen  and (max-width: 768px) {
    border-radius: 10px;
  }
`;

export default CommonBlackBgButton;
