import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const FlipperContainer = styled.div`
  perspective: 1000px;
`;

const Flipper = styled.div`
  position: relative;
  width: 350px;
  height: 350px;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  transform: ${({ flipped }) => (flipped ? 'rotateY(180deg)' : 'rotateY(0deg)')};
`;

const Front = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
`;

const Back = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
`;

const ImageFlipper = ({ frontImage, backImage }) => {
  const [flipped, setFlipped] = useState(false);

  useEffect(() => {
    const initialTimeout = setTimeout(() => {
      setFlipped((prev) => !prev);
      const interval = setInterval(() => {
        setFlipped((prev) => !prev);
      }, 3000);
      return () => clearInterval(interval); 
    }, 2000);

    return () => clearTimeout(initialTimeout); 
  }, []);

  return (
    <FlipperContainer>
      <Flipper flipped={flipped}>
        <Front>
          <img src={frontImage} alt="Front" />
        </Front>
        <Back>
          <img src={backImage} alt="Back" />
        </Back>
      </Flipper>
    </FlipperContainer>
  );
};

export default ImageFlipper;
