import React, { useEffect, useState } from "react";
import {
  StyledCandidatesContainer,
  StyledCandidatesContentContainer,
  StyledCandidatesContentImg,
} from "./Candidates.styled";

import candidatesImage from "../../../Assets/Images/CandidatesImageUpdated.png";
import CommonBlackBgButton from "../../Common/Utils/Buttons/CommonBlackBgButton";
import { useScroll } from "../../../Utils/scrollContext";

const Candidates = () => {
  const  { scrollToJoinMapout } = useScroll()
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    const mediaQuery = window.matchMedia("(min-width: 768px)");
    mediaQuery.addEventListener("change", handler);
    return () => {
      mediaQuery.removeEventListener("change", handler);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {matches ? (
        <>
          <StyledCandidatesContainer>
            <StyledCandidatesContentContainer>
              <h5>See the best & the brightest in action </h5>
              <p>
                Imagine a hiring process where every decision feels like a smart
                investment. Mapout's Career Tasters enable you to do exactly
                that having you observe and assess candidates in real-world
                scenarios through customised virtual internships.{" "}
              </p>
              <CommonBlackBgButton onClick={scrollToJoinMapout}  >Join now</CommonBlackBgButton>

            </StyledCandidatesContentContainer>
            <StyledCandidatesContentImg>
              <span></span>
              <img src={candidatesImage} alt="candidates img" />
            </StyledCandidatesContentImg>
          </StyledCandidatesContainer>
        </>
      ) : (
        <>
          <>
            <StyledCandidatesContainer>
              <h5>See the best & the brightest in action </h5>
              <StyledCandidatesContentImg>
                <span></span>
                <img src={candidatesImage} alt="candidates img" />
              </StyledCandidatesContentImg>
              <StyledCandidatesContentContainer>
                <p>
                  Imagine a hiring process where every decision feels like a
                  smart investment. Mapout's Career Tasters enable you to do
                  exactly that having you observe and assess candidates in
                  real-world scenarios through customised virtual internships.{" "}
                </p>
                <CommonBlackBgButton onClick={scrollToJoinMapout}  >Join now</CommonBlackBgButton>
              </StyledCandidatesContentContainer>
            </StyledCandidatesContainer>
          </>
        </>
      )}
    </>
  );
};

export default Candidates;
