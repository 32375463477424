import styled from "styled-components";

export const StyledCareerTastersWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  flex-direction: column;
  height: auto;

  @media (max-width: 768px) {
    height: auto;
    padding: 4rem 0;

    position: relative;
  }
`;

export const StyledCareerTastersTextWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
  z-index: 9;

  h4 {
    font-size: 38px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    color: rgba(0, 0, 0, 1);
    span {
      color: rgba(71, 114, 244, 1);
    }
  }

  p {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: rgba(0, 0, 0, 1);
    max-width: 80%;
  }

  h5 {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    color: #4772f4;
  }

  @media (max-width: 768px) {
    h4 {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      max-width: 100%;
    }

    h5 {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
    }
  }
`;

export const StyledCareerTastersImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;

  img {
    max-width: 95%;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
export const StyledCareerTastersMobileItemsWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  max-width: 100%;
  padding: 4rem 2rem;
  gap: 1rem;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    right: 0;
    left: 0;
    top: 5rem;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;
export const StyledCareerTastersJoinButton = styled.button`
  border: none;
  background-color: #000;
  color: #fff;
  padding: 12px 32px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-top: -4rem;

  @media screen and (max-width: 768px) {
    margin-top: 2rem;
  }
`;

export const StyledCareerTasterMobileCard = styled.div`
  width: 320px;
  height: 306px;
  background-color: #fff;
  padding: 2rem;
  border-radius: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  box-shadow: 0px 4.77px 9.54px 0px rgba(0, 0, 0, 0.06);
  z-index: 9;

  & > div {
    width: 260px;
    height: 170px;
    background-color: ${({ bg }) => bg && bg};
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    h6 {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      color: #000;
    }
  }

  span {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: rgba(95, 95, 95, 1);
  }
`;
