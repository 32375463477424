import React from "react";
import './index.css';

const SmallAboutMapout = () => {
  return (
    <div className="about-mapout-container">
      <div className="about-mapout-content">
        <p className="about-mapout-text">We merge technology with career wisdom</p>
      </div>
      <div
        className="about-mapout-image"
        style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
      >
        <p className="about-mapout-description">We want to ensure that everyone striving for a successful career finds their perfect fit</p>
      </div>
    </div>
  );
};

export default SmallAboutMapout;
