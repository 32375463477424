import React, { useEffect, useState } from "react";
import {
  StyledCandidateFeatureItem,
  StyledCandidateFeatureItemSmall,
  StyledCandidateFeaturesHeader,
  StyledCandidateFeaturesItemsContainer,
  StyledCandidateFeaturesWrapper,
  StyledCandidatesFeaturesImgWrapperSlider2,
  StyledCandidatesFeaturesItemContentWrapper,
  StyledCandidatesFeaturesItemContentWrapperSmall,
  StyledCandidatesFeaturesItemImgtWrapper,
  StyledCandidatesFeaturesItemImgtWrapperSmall,
  StyledCandidatesFeaturesItemVideo1,
  StyledCandidatesFeaturesItemVideo1Small,
  StyledCandidatesFeaturesItemVideo2,
  StyledCandidatesFeaturesItemVideo2Small,
} from "./CandidateFeatures.styled";
import ArrowButton from "../../Common/Utils/Buttons/ArrowButton";
import { Swiper, SwiperSlide } from "swiper/react";

import video1 from "../../../Assets/Videos/candidateFeatures/video1.mp4";
import video2 from "../../../Assets/Videos/candidateFeatures/video2.mp4";

import image1 from "../../../Assets/Images/talentBoardNew/1.png";
import image2 from "../../../Assets/Images/talentBoardNew/2.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import ImageFlipper from "../../Common/ImageFlipper/ImageFlipper";

const CandidateFeatures = () => {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    const mediaQuery = window.matchMedia("(min-width: 768px)");
    mediaQuery.addEventListener("change", handler);
    return () => {
      mediaQuery.removeEventListener("change", handler);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledCandidateFeaturesWrapper>
      <StyledCandidateFeaturesHeader>
        <ArrowButton
          direction={"left"}
          className="swiper-button-prev-candidates-Features"
        />
        <h3>Exclusive features on MapOut</h3>
        <ArrowButton
          direction={"right"}
          className="swiper-button-next-candidates-Features"
        />
      </StyledCandidateFeaturesHeader>
      <StyledCandidateFeaturesItemsContainer>
        <Swiper
          slidesPerView={matches ? 1.8 : 1}
          centeredSlides={true}
          modules={[Pagination, Navigation]}
          className="mySwiper-candidate-features"
          navigation={{
            nextEl: ".swiper-button-next-candidates-Features",
            prevEl: ".swiper-button-prev-candidates-Features",
          }}
          slideToClickedSlide={true} // Add this line
          style={{ height: matches ? "auto" : "100%" }}
        >
          <SwiperSlide>
            {matches ? (
              <>
                <StyledCandidateFeatureItem>
                  <StyledCandidatesFeaturesItemContentWrapper>
                    <span>Video Profile</span>
                    <h6>Meet the potential behind the CV</h6>
                    <p>
                      MapOut's video profile shows you the real person behind
                      the CV. Watch their personalities and passions convey who
                      they are beyond the text!
                    </p>
                  </StyledCandidatesFeaturesItemContentWrapper>
                  <StyledCandidatesFeaturesItemImgtWrapper>
                    <StyledCandidatesFeaturesItemVideo1>
                      <video
                        src={video2}
                        alt=""
                        autoPlay
                        loop
                        muted
                        controls={false}
                        playsInline  
                      />
                    </StyledCandidatesFeaturesItemVideo1>
                    <StyledCandidatesFeaturesItemVideo2>
                      <video
                        src={video1}
                        alt=""
                        autoPlay
                        loop
                        muted
                        controls={false}
                        playsInline  
                      />
                    </StyledCandidatesFeaturesItemVideo2>
                  </StyledCandidatesFeaturesItemImgtWrapper>
                </StyledCandidateFeatureItem>
              </>
            ) : (
              <StyledCandidateFeatureItemSmall>
                <span>Video Profile</span>
                <StyledCandidatesFeaturesItemImgtWrapperSmall
                  style={{ position: "relative" }}
                >
                  <StyledCandidatesFeaturesItemVideo2Small>
                    <video
                      src={video1}
                      alt=""
                      autoPlay
                      loop
                      muted
                      controls={false}
                      playsInline
                    />
                  </StyledCandidatesFeaturesItemVideo2Small>
                  <StyledCandidatesFeaturesItemVideo1Small>
                    <video
                      src={video2}
                      alt=""
                      autoPlay
                      loop
                      muted
                      controls={false}
                      playsInline
                    />
                  </StyledCandidatesFeaturesItemVideo1Small>
                </StyledCandidatesFeaturesItemImgtWrapperSmall>
                <StyledCandidatesFeaturesItemContentWrapperSmall>
                  <h6>Meet the potential behind the CV</h6>
                  <p>
                    MapOut's video profile shows you the real person behind the
                    CV. Watch their personalities and passions convey who they
                    are beyond the text!
                  </p>
                </StyledCandidatesFeaturesItemContentWrapperSmall>
              </StyledCandidateFeatureItemSmall>
            )}
          </SwiperSlide>
          <SwiperSlide>
            {matches ? (
              <>
                {" "}
                <StyledCandidateFeatureItem>
                  <StyledCandidatesFeaturesItemContentWrapper>
                    <span>Talent Board</span>
                    <h6>Where potential meets possibility</h6>
                    <p>
                      Talentboard transforms a traditional CV in a live gallery
                      of skills and talents that comes to life through the
                      projects and work shared on this platform.
                    </p>
                  </StyledCandidatesFeaturesItemContentWrapper>
                  <StyledCandidatesFeaturesImgWrapperSlider2>
                    {/* <img src={image1} alt=" talend board " /> */}
                    <ImageFlipper frontImage={image1} backImage={image2}  />

                  </StyledCandidatesFeaturesImgWrapperSlider2>
                </StyledCandidateFeatureItem>
              </>
            ) : (
              <>
                <StyledCandidateFeatureItem>
                  <span>Talent Board</span>
                  <StyledCandidatesFeaturesImgWrapperSlider2>
                    {/* <img src={image1} alt=" talend board " /> */}
                    <ImageFlipper frontImage={image1} backImage={image2}  />
                  </StyledCandidatesFeaturesImgWrapperSlider2>
                  <StyledCandidatesFeaturesItemContentWrapper>
                    <h6>Where potential meets possibility</h6>
                    <p>
                      Talentboard transforms a traditional CV in a live gallery
                      of skills and talents that comes to life through the
                      projects and work shared on this platform.
                    </p>
                  </StyledCandidatesFeaturesItemContentWrapper>
                </StyledCandidateFeatureItem>
              </>
            )}
          </SwiperSlide>
        </Swiper>
      </StyledCandidateFeaturesItemsContainer>
    </StyledCandidateFeaturesWrapper>
  );
};

export default CandidateFeatures;
