import React from "react";
import styled from "styled-components";
import FooterLogo from "../../Assets/Icons/Mapout-footer-logo.svg";
import logoName from "../../Assets/Icons/mapout-name.svg";
import LinkedinIcon from "../../Assets/Icons/Footer-LinkedIn.svg";
import InstagramIcon from "../../Assets/Icons/Footer-Instagram.svg";
import { useNavigate } from "react-router-dom";

const FooterContainer = styled.footer`
  width: 100%;
  overflow: hidden;
`;

const FooterContent = styled.div`
  width: 100%;
  display: flex;
  background-color: #000;
  height: auto;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const FooterSection = styled.div`
  height: auto;
  padding: 42px 64px 16px 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  align-self: baseline;
`;

const FooterText = styled.p`
  color: #fff;
  margin-top: 8px;
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 500;
`;

const NavLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const NavLink = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 28.6px;
  text-align: left;
  cursor: pointer;

  &:hover {
    color: #d4d4d4;
  }
`;

const SocialIconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Divider = styled.div`
  height: 0.1px !important;
  width: 100%;
  background-color: #fff;
  margin-top: 16px;
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px 32px;
  align-items: center;
`;

const FooterBottomText = styled.p`
  color: #fff;
  font-size: 14px;
  font-weight: 150;
  cursor: pointer;
`;

const Footer = () => {
  const navigate = useNavigate();

  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <div>
            <LogoContainer onClick={() => navigate("/")}>
              <img
                src={FooterLogo}
                alt="Footer Logo"
                style={{ height: "2.5rem" }}
              />
              <img
                src={logoName}
                alt="Mapout Logo"
                style={{ height: "1rem", marginLeft: "16px" }}
              />
            </LogoContainer>
            <FooterText>Your Complete Career Ecosystem</FooterText>
          </div>
          <NavLinksWrapper>
            <NavLink onClick={() => navigate("/")}>For Talent</NavLink>
            <NavLink onClick={() => navigate("/employers")}>
              For Employers
            </NavLink>
            <NavLink onClick={() => navigate("/about-us")}>About Us</NavLink>
            <NavLink onClick={() => navigate("/open-positions")}>
              Open Positions
            </NavLink>
            <SocialIconsContainer>
              <NavLink onClick={() => navigate("/contact-us")}>
                Contact Us
              </NavLink>
              <div>
                <img
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/mapoutglobal",
                      "_blank"
                    )
                  }
                  src={LinkedinIcon}
                  alt="LinkedIn Icon"
                  style={{ height: "2rem", cursor: "pointer" }}
                />
                <img
                  onClick={() =>
                    window.open("https://instagram.com/mapoutglobal", "_blank")
                  }
                  src={InstagramIcon}
                  alt="Instagram Icon"
                  style={{
                    height: "2rem",
                    marginLeft: "12px",
                    cursor: "pointer",
                  }}
                />
              </div>
            </SocialIconsContainer>
          </NavLinksWrapper>
        </FooterSection>
        <Divider />
        <FooterBottom>
          <FooterBottomText>
            Copyright © 2024. All rights reserved.
          </FooterBottomText>
          <div style={{ display: "flex", alignItems: "center" }}>
            <FooterBottomText onClick={() => navigate("/termsOfUse")}>
              Terms & conditions
            </FooterBottomText>
            <FooterBottomText style={{ margin: "0 4px" }}>|</FooterBottomText>
            <FooterBottomText onClick={() => navigate("/privacypolicy")}>
              Privacy policy
            </FooterBottomText>
          </div>
        </FooterBottom>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
